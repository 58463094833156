<template>
  <div class="bg-[#F5F5F5] w-[100%] info-newsbox">
    <div>
      <div class="flex py-[20px] text-[14px] text-[#606266]">
        当前位置><el-link @click="goRoute('/')">首页</el-link> ><el-link @click="goRoute(path)">{{ name }}</el-link>
        >{{ name }}详情
      </div>
      <div class="w-[100%] bg-white rounded-sm py-[60px]">
        <div class="w-[1080px] mx-auto">
          <h2 class="text-center text-[#333] text-[22px]">
            {{ page?.title || "政策指导文件" }}
          </h2>
          <div class="flex justify-center py-[20px] text-[#666] text-[14px] leading-[14px]">
            <!-- <span class="mr-[20px]">来源 {{ page?.createBy }}</span> -->
            <span class="mr-[20px]">时间 {{ page?.createTime }}</span>
            <span>浏览量 {{ page?.pv }}</span>
          </div>
          <!-- <div
            class="bg-[#F5F5F5] px-[20px] py-[24px] mx-auto rounded-[10px]"
            v-show="page?.digest"
          >
            <p class="text-[16px] text-[#333] mb-[10px]">文章摘要：</p>
            <p class="text-[14px] text-[#666]">{{ page?.digest }}</p>
          </div> -->
          <div v-html="page?.content" class="page-content indent-[2em]"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watchEffect, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { getDetail } from "@/http/api";
const path = ref("");
const name = ref("");
const router = useRouter();
const page = ref<IResult>();
const pageType = {
  "/news": "新闻中心",
  "/policies": "政策法规",
  '/partyBuilding': "国企党建",
  '/aboutus': "关于我们",
};
watchEffect(() => {
  path.value = "/" + router.currentRoute.value.path.split("/")[1];
  name.value = pageType[path.value];
});
function goRoute(path) {
  router.push(path);
}
interface IResult {
  content: string;
  createTime: string;
  createBy: string;
  title: string;
  digest: string;
  pv: string;
}
onMounted(() => {
  const { id } = router.currentRoute.value.query;
  getDetail<IResult>({ id }).then((res) => {
    page.value = res.data;
  });
});
</script>

<style scoped lang="scss">
// ::v-deep .page-content {
//   div:first-child,
//   p:first-child {
//     text-indent: 2em;
//   }
// }
@media (max-width: 750px) {
  .info-newsbox>div>div.flex {
    padding: 18px 0 18px 3%;
  }

  .info-newsbox>div>div.rounded-sm {
    padding: 30px 3%;
  }

  .info-newsbox>div>div.rounded-sm>div {
    width: 100%;
  }

  .info-newsbox>div>div.rounded-sm>div>h2 {
    padding-top: 10px;
    font-weight: bold;
  }

  .info-newsbox>div>div.rounded-sm>div>div:nth-child(4) p {
    line-height: 24px;
  }
}
</style>
