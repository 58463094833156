<template>
    <div class="flex  pt-[10px]">
        <div class="left sticky top-[100px] left-0 h-[600px] z-10" ref="refContent">

            <div v-for="(item) in dataList" :key="item.id" @click="anchor(item.title)"
                class="text-[26px]  font-bold pr-[24px] right-pointer cursor-pointer mt-[-10px]"
                :class="[currentId == item.title ? 'text-[var(--el-color-primary)] active' : 'text-[#333333]']">{{
                item.title
            }}</div>

        </div>
        <div class="right z-1 relative" @scroll="handleScroll" ref="leftRef">
            <div v-for="( item, index ) in  dataList" :title="item.title" :ref="el => myRefs[index] = el" :key="item.id">
                <a :id="item.title" class=" block" />

                <p class="title">{{ item.digest }}</p>
                <div class="news indent-[2em]" v-html="item.content" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { withDefaults, defineProps, ref, watch, nextTick, Ref } from 'vue';
import { IItem } from '@/interface/article.d';
interface IProps {
    dataList: Array<Partial<IItem>>;
}
const leftRef = ref();

const currentId = ref('');

const myRefs = ref([]) as Ref<any[]>;

const refContent = ref<HTMLDivElement | null>(null)
/*锚链接跳转*/
function anchor(anchorName) {
    currentId.value = anchorName
    /*找到锚点*/
    let anchorElement = document.getElementById(anchorName);
    /*如果对应id的锚点存在，就跳转到锚点*/
    if (anchorElement) {

        anchorElement.scrollIntoView();
        nextTick(() => {
            if (anchorElement) {
                document.documentElement.scrollTop -= 90
            }
        })

    }
}
const props = withDefaults(defineProps<IProps>(), {
    dataList: () => [{}]
});

watch(() => props.dataList, (val) => {
    if (val.length) {
        currentId.value = props.dataList[0].title!;
        nextTick(() => {
            if (refContent.value && leftRef.value) {
                refContent.value.style.height = refContent.value.scrollHeight + 'px';
                leftRef.value.style.height = refContent.value.scrollHeight + 'px';
            }
        })
    }
}, { deep: true })

const handleScroll = (e) => {
    // 滚动后的高度
    const scrollTop = e.target.scrollTop

    // a: 滚动时，获取当前可视区域的元素
    const currentElement = myRefs.value.find((item) => {
        if (item) {
            const offsetTop = item.offsetTop
            const offsetHeight = item.offsetHeight
            return scrollTop >= offsetTop && scrollTop < offsetTop + offsetHeight
            // q: offsetTop是元素相对于父元素的偏移量，offsetHeight是元素的高度，scrollTop是滚动条滚动的距离
        }
    })
    // a: 获取currentElement元素上的title属性
    if (currentElement) {
        currentId.value = currentElement.title
    }
}
</script>

<style scoped lang="scss">
.history {
    display: block;
}

.right-pointer {
    position: relative;

    &:not(:last-child) {
        padding-bottom: 50px;
    }


    &::after {
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        background: #FFFFFF;
        border-radius: 50%;
        border: 3px solid #333;
        right: -11px;
        z-index: 1;
        top: 10px;
    }

    &::before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        background: #FFFFFF;
        border-radius: 50%;
        border: 3px solid #333;
        right: -6px;
        top: 15px;
        z-index: 2;
    }

    &.active {

        &::after,
        &::before {
            border-color: var(--el-color-primary);
        }
    }
}

.left {
    // margin-top: -10px;
    flex: 1;
    text-align: right;
    // padding-top: 10px;
    border-right: 2px solid #CCC;


    .pic {
        width: 100%;
        height: 100px;
        border-radius: 4px;

        img {
            height: 100%;
        }

        .year {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 5px;
            color: #fff;
            background-color: rgba(0, 0, 0, .2);
        }
    }
}

.active {
    .right {
        .title {
            color: var(--el-color-primary);
        }

        .icon {
            border: 2px solid var(--el-color-primary);

            i {
                background-color: var(--el-color-primary);
            }
        }
    }


}

.right {
    flex: 3;
    position: relative;
    overflow-y: auto;
    padding-top: 10px;
    padding-left: 40px;
    // border-left: 2px solid #CCC;

    .title {
        font-size: 16px;
    }

    .icon {
        position: absolute;
        left: -9px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        margin-top: 5px;
        border-radius: 50%;
        border: 2px solid #ccc;
        background-color: #fff;

        i {
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #ccc;
        }
    }

    .news {
        margin-top: 10px;
        color: #6b6969;
    }
}

@media (max-width: 750px) {
    .page-abouts div.history>div {
        flex-flow: column wrap;
        margin-top: 30px;
    }

    .page-abouts div.history>div>div.left {
        text-align: center;
    }

    .page-abouts div.history>div>div.left>div.pic {
        height: auto;
    }

    .page-abouts div.history>div>div.right {
        border: none;
    }

    .page-abouts div.history>div>div.right>span.icon {
        position: inherit;
        margin: 0 auto;
        left: 0;
    }

    .page-abouts div.history>div>div.right p.title {
        text-align: center;
        font-size: 22px;
        line-height: 40px;
        font-weight: bold;
        padding-top: 10px;
    }

    .page-abouts div.history>div>div {
        padding-left: 0;
        padding-right: 0;
    }
}
</style>