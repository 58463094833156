import http from "../index";
//  导航接口
export const getFacultyMenuKind = <T = any>() => {
  return http.get<T>("/web/faculty/nav");
};
//  侧边菜单、二级分类
export const getLeaf = <T = any>(params) => {
  return http.get<T>("/web/faculty/leaf", params);
};
// 文章分页分页
export const getPage = <T = any>(params) => {
  return http.get<T>("/web/faculty/article", params);
};
// 文章详情
export const getDetail = <T = any>(params) => {
  return http.get<T>("/web/article/get/one", params);
};
// 员工风采
export const getConfig = <T = any>() => {
  return http.post<T>("/web/config/list");
};
