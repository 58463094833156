<template>
    <div
        class="fixed top-0 left-0 z-50 justify-between w-[100%] h-[80px] nav-bar-wrap"
        :class="[scrollTopValue > 0 ? 'bg-white' : 'bg-white/50']"
    >
        <div
            class="h-[100%] w-[1200px] m-auto flex items-center justify-between max-[750px]:w-[100%]"
        >
            <div class="h-[60px] flex items-center nav-bar-logo">
                <img :src="curLogo" class="h-[47px]" />
            </div>
            <el-menu
                :ellipsis="navList.length > 8"
                :default-active="activeIndex"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect"
            >
                <template v-for="item in navList">
                    <el-menu-item
                        v-if="
                            !item.children ||
                            item.children.length === 0 ||
                            item.children.length > 0
                        "
                        :index="item.path"
                        :key="item.id"
                        >{{ item.title }}</el-menu-item
                    >
                    <template v-else>
                        <el-sub-menu :index="item.path" :key="item.id">
                            <template #title>{{ item.title }}</template>
                            <el-menu-item
                                :index="demo.path"
                                v-for="demo in item.children"
                                :key="'child' + demo.id"
                                >{{ demo.name }}</el-menu-item
                            >
                        </el-sub-menu>
                    </template>
                </template>
            </el-menu>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {
    ref,
    defineOptions,
    watchEffect,
    onUnmounted,
    onMounted,
    computed,
    watch,
    nextTick,
} from 'vue'
import { useRouter } from 'vue-router'
import { getCurrentInstance } from 'vue'
import IndexLogo from '@/assets/images/logo.png'
import { INavList } from '@/interface/index'
import { useStore } from '@/store/index'
import { getConfig, getFacultyMenuKind } from '@/http/api/index'
import { changeThemeColor } from '@/utils/themeConfig'
defineOptions({
    name: 'NavBar',
})
const store = useStore()
const { proxy } = getCurrentInstance() as any
const activeIndex = ref()
const router = useRouter()
const scrollTopValue = ref(0)
const curLogo = computed(() => {
    if (navList.value.length > 0 && navList.value[0].logo) {
        return navList.value[0].logo
    }
    return IndexLogo
})
function onScroll() {
    const scrollTop =
        document.documentElement.scrollTop || document.body?.scrollTop
    scrollTopValue.value = scrollTop
}
const navList = ref<Array<INavList>>([])
function arrToTree(arr: Array<INavList> = []) {
    arr.forEach((v: any) => {
        v.name = v.title
        if (v.parentId != 0) {
            const parentItem = arr.find((child) => child.id == v.parentId)
            if (parentItem) {
                if (parentItem.children) {
                    parentItem.children.push(v)
                } else {
                    parentItem.children = [v]
                }
            }
        }
    })
    return arr.filter((v) => !v.parentId)
}
getThemeConfig()
function getThemeConfig() {
    getConfig().then((res: any) => {
        if (res.data?.length) {
            store.commit('setConfigList', res.data)
            changeThemeColor(res.data[0].skin || '#ff0000')
        } else {
            changeThemeColor('#ff0000')
        }
    })
}
onMounted(() => {
    window.addEventListener('scroll', onScroll)
    getFacultyMenuKind().then((res: any) => {
        store.commit('setFacultyMenuKind', res.data)
        navList.value = arrToTree(res.data)
        proxy.$eventBus.emit('navMenuInit')
    })
})
onUnmounted(() => {
    window.removeEventListener('scroll', onScroll)
})

watch(
    () => router.currentRoute.value,
    () => {
        nextTick(() => {
            document.body.scrollTop = document.documentElement.scrollTop = 0
        })
    }
)
watchEffect(() => {
    activeIndex.value = '/' + router.currentRoute.value.path.split('/')[1]
})

const handleSelect = (key: string, keyPath: string[]) => {
  
  if(key === '/feestandards') {
    window.open('http://www.gdrising.com.cn/')
    return
  }

  router.push(key)
}
</script>
<style scoped lang="scss">
.el-menu--horizontal > .el-menu-item,
.el-menu--horizontal.el-menu {
    border-bottom: none;
    background-color: transparent;
}
.el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: none;
    background-color: unset;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    background-color: unset;
}
</style>
<style>
@media (max-width: 750px){
  .nav-bar-wrap{
    width: 100% !important; 
    padding: 10px 0; 
    background-color: #fff !important; 
    box-shadow: 0 2px 6px rgba(0,0,0,.2);
    flex-flow: column wrap; 
    height: auto !important; 
    position: inherit !important;
  }
  .nav-bar-wrap > div {
    display: block;
    padding: 0 3%;
    justify-content: space-between;
  }
  .nav-bar-wrap ul.el-menu-demo{
    width: 100%;
    height: auto;
    background-color: #fff !important;
    flex-wrap: wrap;
  }
  .el-menu--horizontal>.el-menu-item{
    padding: 8px 0 0;
    width: 25%;
    line-height: 50px;
    height: 50px !important;
  }
}
</style>
