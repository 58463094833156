import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store, { key } from "./store";
import ElementPlus from "element-plus";
import "@/assets/css/normalize.css";
import "element-plus/dist/index.css";
import "tailwindcss/tailwind.css";
import "@/assets/font/iconfont.css";
import mitt from "mitt";
// import "swiper/swiper.min.css"
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css";
const app = createApp(App);
// 注册事件总线
app.config.globalProperties.$eventBus = mitt();
app
  .use(store, key)
  .use(router)
  .use(VueAwesomeSwiper)
  .use(ElementPlus)
  .mount("#app");
