import { useStore } from "@/store/index";
import { ref, watch } from "vue";
import { IConfig } from "@/interface/index";
export default () => {
  const store = useStore();
  const qrCodesObj = ref<Partial<IConfig>>({});
  watch(
    () => store.state.configList,
    (configList) => {
        qrCodesObj.value = configList.find(item => item.mobileQrcode && item.wechatQrcode) || {}
    },
    {
      immediate: true,
    }
  );
  return {
    qrCodesObj
  };
}
