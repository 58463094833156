<template>
  <div class="bg-white list-compone-wrap newslist-conn">
    <MutilListSwiper
      :NavList="data.NavList"
      :swiperList="data.swiperList"
      @toDetail="handleEmit"
      :is-empty="!pagination.total"
      @hanldeChange="hanldeChange"
    />
    <div
      class="bg-white px-[60px] pb-[60px] news-article-wrap"
      v-show="pagination.total"
    >
      <div
        v-for="item in data.articleList"
        :key="item.id"
        class="list-li flex mb-[48px] cursor-pointer"
        @click="handleEmit(item)"
      >
        <img
          :src="item.banner"
          class="w-[380px] h-[168px] mr-[24px] object-cover flex-shrink-0"
        />
        <div class="flex-1">
          <p class="title text-[18px] text-[#333] leading-[20px] mb-[20px]">
            {{ item.title }}
          </p>
          <span
            class="text-[14px] leading-[14px] text-[#999] inline-block border-b-[2px] border-[#666] pb-[5px]"
            >{{ item.createTime }}</span
          >
          <p
            class="w-[100%] h-[66px] text-[14px] text-[#666] mt-[20px] leading-[22px] desc indent-[2em]"
            v-html="item.digest"
          ></p>
        </div>
      </div>
      <div class="flex justify-center">
        <el-pagination
          prev-text="上一页"
          next-text="下一页"
          background
          layout="prev, pager, next"
          :current-page="pagination.pageNum"
          :total="pagination.total"
          :page-size="pagination.pageSize"
          @current-change="currentChange"
        />
      </div>
    </div>

    <el-empty description="暂无数据" v-show="!pagination.total"></el-empty>
  </div>
</template>

<script setup lang="ts">
import MutilListSwiper, {
  IListItem,
} from "@/components/SwiperComp/MutilListSwiper.vue";
import {
  defineProps,
  withDefaults,
  defineEmits,
  reactive,
  onMounted,
  ref,
  toRaw,
  nextTick,
} from "vue";
import { IPagination } from "@/interface/index";
import { getPage } from "@/http/api/index";
import useGetNewsNavList from "@/hooks/useGetNewsNavList";
import { IItem } from "@/interface/newsInterface";
const emit = defineEmits(["handleClick"]);
interface IProps {
  type: string;
}
const pagination = reactive<IPagination>({
  total: 0,
  pageSize: 4,
  pageNum: 1,
});

const searchParams = reactive({
  menuId: "",
});
const data = reactive<{
  swiperList: Array<IItem>;
  NavList: Array<IListItem>;
  articleList: Array<IItem>;
}>({
  swiperList: [],
  NavList: [],
  articleList: [],
});
const props = withDefaults(defineProps<IProps>(), {
  type: "",
});
const menuPath = props.type == "news" ? "/news" : "/policies";
const { getNewsInfo } = useGetNewsNavList(menuPath, pullData);
function handleEmit(item) {
  emit("handleClick", item);
}
function currentChange(pageNum) {
  console.log(pageNum)
  pagination.pageNum = pageNum;
  getSelPage();
}
function pullData(res) {
  console.log(res);
  data.NavList = res.data.leftList || [];
  console.log(data.NavList);
  if (!data.NavList.length) return;
  pagination.pageNum = 1;
  searchParams.menuId = data.NavList[0].id;
  getSelPage();
}

function getSelPage() {
  getPage<Array<IItem>>({
    ...toRaw(searchParams),
    pageNum: pagination.pageNum,
    pageSize: pagination.pageSize,
  }).then((res) => {
    pagination.total = res.total as number;
    if (pagination.pageNum == 1) {
      data.swiperList = [];
      nextTick(() => {
        data.swiperList = res.rows?.slice(0, 3);
      });
      
    } 
    data.articleList = res.rows;
  });
}

function hanldeChange(index) {
  pagination.pageNum = 1;
  searchParams.menuId = data.NavList[index]?.id;
  getSelPage();
}

onMounted(() => {
  getNewsInfo();
});
</script>

<style scoped lang="scss">
.desc {
  overflow: hidden; // 溢出隐藏
  text-overflow: ellipsis; // 溢出用省略号显示
  display: -webkit-box; // 作为弹性伸缩盒子模型显示。
  -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式：从上到下垂直排列
  -webkit-line-clamp: 3; // 显示的行数
}
.list-li {
  &:hover {
    .title {
      color: var(--el-color-primary);
    }
  }
}
@media (max-width: 750px){
  .newslist-conn{
    padding-top: 30px;
  }
  .newslist-conn > div:first-child{
    padding-top: 10px;
  }
  .newslist-conn > div:first-child div.mutilist-nav > div{
    margin: 0 10px;
  }
}
</style>
