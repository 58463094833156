<template>
  <div class="home mb-[60px]">
    <div class="w-[100%]" :style="{ height: videoHeight }">
      <video
        :src="videoUrl"
        class="w-[100%] h-[100%] object-cover"
        loop
        preload="auto"
        autoplay
        muted
        :controls="isMobile"
        ref="videoRef"
      ></video>
    </div>
    <!-- <SwiperComp></SwiperComp> -->
    <div class="mx-auto home-wrap w-[1200px] max-[750px]:w-[100%]">
      <company-active />
      <SwiperElegant class="mt-[80px]"></SwiperElegant>
      <!-- <mien-swiper></mien-swiper> -->
    </div>
    <AboutComp />
  </div>
</template>

<script lang="ts" setup>
// import SwiperComp from "@/components/SwiperComp/IndexBannerSwiper.vue";
import CompanyActive from "@/components/HomeComp/CompanyActive.vue";
// import MienSwiper from "@/components/HomeComp/MienSwiper.vue";
import SwiperElegant from "@/components/HomeComp/swiperComp.vue";
import AboutComp from "@/components/HomeComp/AboutComp.vue";
import { onMounted, onUnmounted, ref } from "vue";
const videoUrl = ref("");
const videoRef = ref<HTMLVideoElement | null>(null);
const videoHeight = ref("auto");
import useBanner from "@/hooks/useBanner";
import useGetDevice from "@/hooks/useGetDevice";
// import { ElMessage, ElMessageBox } from "element-plus";
const {
  versions: { isMobile },
} = useGetDevice();
const contrlBtn = ref<HTMLElement | null>(null);
useBanner(function (meneItem) {
  if (!meneItem.banner) return;
  meneItem.banner && (videoUrl.value = meneItem.banner);
  if (isMobile) {
    videoHeight.value = "auto";
  } else {
    videoHeight.value = window.innerHeight + "px";
  }
});
</script>
