import useGetContext from "@/hooks/useGetContext";
import { onUnmounted, watch } from "vue";
import useNavigation from "./useNavigation";
export default function useBanner(pullData) {
  const { proxy, getMenuId } = useGetContext();
  const { router } = useNavigation();
  let { newMenu } =
    getMenuId("/" + router.currentRoute.value.path.split("/")[1]) || {};
  function pullNewData() {
    newMenu = (
      getMenuId("/" + router.currentRoute.value.path.split("/")[1]) || {}
    ).newMenu;
    pullData && pullData.call(null, newMenu);
  }
  proxy.$eventBus.on("navMenuInit", pullNewData);
  let timer: number | null = null;
  const watcher = watch(
    () => router.currentRoute.value,
    () => {
      timer && clearTimeout(timer);
      timer = setTimeout(() => {
        newMenu = (
          getMenuId("/" + router.currentRoute.value.path.split("/")[1]) || {}
        ).newMenu;
        pullData && pullData.call(null, newMenu);
      }, 50);
    },
    {
      immediate: true,
    }
  );
  onUnmounted(() => {
    watcher();
    proxy.$eventBus.off("navMenuInit", pullNewData);
  });
}
