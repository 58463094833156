<template>
    <div class="mt-4">
        <div class="flex text-[16] mb-[10px] gap-[40px]">
            <div  v-for="(item) in  dataList " :key="item.id"  class="flex-1">
                <div class="info-panel">
                    <a class="info-panel-item active" href="javascript:void">{{ item.title }}</a>
                </div>
                <ul class="mt-[26px]" v-if="item.list?.length">
                    <li class="doc" v-for="demo in item.list" :key="demo.id" @click="goDetail(demo)">
                        <span class="flex-1 overflow-hidden whitespace-nowrap text-ellipsis">{{ demo.title }}</span>
                    </li>
                </ul>
            </div>
            <!-- <div class="flex-1">
                <div class="info-panel">
                    <a class="info-panel-item" :class="activeName2 === '信息披露' ? 'active' : ''" @click="handleClick2('信息披露')" href="javascript:void">信息披露</a>
                </div>
            </div> -->
        </div>
        <!-- <div class="flex w-full h-[116px] justify-between items-center px-[30px] rounded-[10px] bg-[#FFF7F4]">
            <div>
                <img class="inline-block w-[50px] h-[47px] mr-[15px] align-[-10px]" src="@/assets/personnel1.png" alt="">
                <span class="text-[24px] text-[#333]">政府文件信息公开</span>
            </div>
            <div>
                <img class="inline-block w-[48px] h-[47px] mr-[15px] align-[-10px]" src="@/assets/personnel2.png" alt="">
                <span class="text-[24px] text-[#333]">政策解读信息公开</span>
            </div>
            <div>
                <img class="inline-block w-[44px] h-[48px] mr-[15px] align-[-13px]" src="@/assets/personnel3.png" alt="">
                <span class="text-[24px] text-[#333]">企业人事信息公开</span>
            </div>
        </div> -->
        <!-- <p class="item text-[16] mb-[10px]">累计获得省部级以上奖项210项，其中国家级奖项16项；多项创新指标持续稳居省属企业第一</p>
        <p class="item text-[16] mb-[10px]">继荣获2018年国家科学技术进步奖二等奖，又荣获2019年国家科学技术进步奖一等奖</p>
        <p class="item text-[16] mb-[10px]">控制的矿产资源分布在4大洲9个国家，铅锌铜采、选、冶综合能力稳居全国前列，全资控股的广东稀土产业集团是国务院确定国家级稀土企业</p> -->
    </div>
</template>
  
<script lang="ts" setup>
import { withDefaults, defineProps, ref, computed } from 'vue';
import useNavigation from "@/hooks/useNavigation";
import { IItem } from '@/interface/article.d';
// import type { TabsPaneContext } from 'element-plus'
interface IProps {
    dataList: Array<Partial<IItem>>;
}

const { push } = useNavigation();

// const 最新公开

const props = withDefaults(defineProps<IProps>(), {
    dataList: () => [{}]
});

// const activeName = ref<any>(props.dataList[0]?.title);
// const activeName2 = ref<any>(props.dataList[1]?.title);

// activeName计算属性 ref
// const activeNameHTML = computed(() => {
//   return props.dataList?.find(item => item.title === activeName.value)?.content || ''
// })

// activeName计算属性 ref
// const activeName2HTML = computed(() => {
//   return props.dataList?.find(item => item.title === activeName2.value)?.content || ''
// })

// onMounted(() => {
//     setTimeout(() => {
//     }, 2000);
// })

// watch(
//   () => props.dataList,
//   (value) => {
//     if(value) {
        
//     }
//   }
// )

// const handleClick = (tab) => {
//   console.log(tab)
//   activeName.value = tab
// }

// const handleClick2 = (tab) => {
//   console.log(tab)
//   activeName2.value = tab
// }

function goDetail({ id }) {
  push({
    path: "/aboutus/detail",
    query: {
      id,
    },
  });
}

</script>

<style scoped lang="scss">
.info-panel {
    color: #333;
    font-size: 16px;
    border-bottom: 1px solid #CDCDCD;
}

.info-panel-item {
    display: inline-block;
    margin-right: 20px;
    padding: 10px 0;
    &:hover {
        cursor: pointer;
    }
    &.active {
        margin-bottom: -1px;
        font-weight: bold;
        color: #EB4000;
        border-bottom: 2px solid #EB4000;
    }
}

.title {
    // position: relative;
    // width: 420px;
    // padding-left: 15px;
    // font-size: 14px;
    // color: #666;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;
    :deep() {
        li {
            padding-left: 15px;
            font-size: 14px;
            // color: #666;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: 8px;
                left: 3px;
                width: 5px;
                height: 5px;
                background: #C1C1C1;
                border-radius: 50%;
            }
        }
    }
}

.doc {
  display: flex;
  align-items: center;
  @apply flex-1 text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer text-[#666] text-[16px] leading-[20px] pb-[16px] hover:text-[var(--el-color-primary)];

  &::before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--el-color-primary);
    margin-right: 8px;
    flex-shrink: 0;
  }
}
</style>
  