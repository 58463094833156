<template>
	<div class="footer relative">
		<div class="logo mb-[20px]">
			<img class="img h-[56px] text-[#fff]" :src="curLogo" />
			<!-- <LogoSvg /> -->
		</div>
		<div class="content flex flex-row">
			<div class="flex-1">
				<p class="title font-bold">友情连接</p>
				<ul>
					<li v-for="item in footerInfo.blogroll" :key="item.name"><a :href="item.val" target="_blank">{{
						item.name }}</a></li>
					<!-- <li><a href="http://www.cnafc.org/cnafc/front/index" target="_blank">财务公司协会</a></li>
					<li><a href="https://www.vanlinks.cn/index.html" target="_blank">万链指数</a></li>
					<li><a href="http://www.pbc.gov.cn/" target="_blank">中国人民银行</a></li>
					<li><a href="http://www.csrc.gov.cn/" target="_blank">证监会</a></li>
					<li><a href="http://www.safe.gov.cn/" target="_blank">国家外汇管理局</a></li> -->
				</ul>
			</div>
			<div class="flex-1">
				<p class="title font-bold">快捷导航</p>
				<ul>
					<li class="cursor-pointer" @click="forwardAboutus('intro')">公司概况</li>
					<li class="cursor-pointer" @click="forwardAboutus('joinus')">企业文化</li>
					<li class="cursor-pointer" @click="forwardAboutus('honors')">资质荣誉</li>
					<li class="cursor-pointer" @click="forwardAboutus('joinus')">加入我们</li>
					<li class="cursor-pointer" @click="forwardAboutus('policies')">国企党建</li>
					<li class="cursor-pointer" @click="forwardAboutus('financial')">金融服务</li>
				</ul>
			</div>
			<div class="flex-1">
				<p class="title font-bold">联系我们</p>
				<ul>
					<li class="line" v-for="item in footerInfo.facultyName" :key="item.name">{{ item.name }}：{{ item.val }}
					</li>
				</ul>
			</div>
		</div>
		<div class="copyright absolute bottom-0 left-0">版权所有 © 广东省广晟财务有限公司 ICP备案：粤ICP备13083179号</div>
	</div>
</template>

<script lang="ts" setup>
import LogoSvg from "@/assets/images/logo.png";
import { useRouter } from "vue-router";
import { INavList } from '@/interface/index'
import { useStore } from '@/store/index'
import { reactive, watch, ref, computed } from "vue";
const router = useRouter();
const footerInfo = reactive<{
	facultyName: Array<{ name: string, val: string }>,
	blogroll: Array<{ name: string, val: string }>,
}>({
	facultyName: [],
	blogroll: []
})
const store = useStore()
const navList = ref<Array<INavList>>([])

const curLogo = computed(() => {
    if (navList.value.length > 0 && navList.value[0].logo) {
        return navList.value[0].logo
    }
    return LogoSvg
})

watch(() => store.state.configList, (val) => {
	if (store.state.configList?.length > 0) {
		const { facultyName, blogroll } = store.state.configList[0]
		footerInfo.facultyName = facultyName && JSON.parse(facultyName)
		footerInfo.blogroll = blogroll && JSON.parse(blogroll)
	}
}, { deep: true, immediate: true })

watch(() => store.state.navList, (val) => {
	if(store.state.navList?.length > 0) {
		const vals = JSON.parse(JSON.stringify(store.state.navList))
		navList.value = arrToTree(vals)
	}
})

function arrToTree(arr: Array<INavList> = []) {
    arr.forEach((v: any) => {
        v.name = v.title
        if (v.parentId != 0) {
            const parentItem = arr.find((child) => child.id == v.parentId)
            if (parentItem) {
                if (parentItem.children) {
                    parentItem.children.push(v)
                } else {
                    parentItem.children = [v]
                }
            }
        }
    })
    return arr.filter((v) => !v.parentId)
}
const forwardAboutus = (tab) => {

	switch (tab) {
		case 'background':
		case 'intro':
		case 'honors':
			router.push({
				path: '/aboutus',
				query: { tab }
			})
			break;
		case 'joinus':
			router.push('/joinus')
			break;
		case 'policies':
			router.push('/partyBuilding')
			break;
		case 'financial':
			router.push('/financial')
			break;
	}
}
</script>

<style scoped lang="scss">
.footer {
	width: 100%;
	height: 480px;
	padding: 60px 0;
	background: linear-gradient(to bottom right, var(--el-color-primary), var(--el-color-primary-dark-2));
	background-size: 100% 100%;
}

.logo {
	margin-bottom: 50px;
	text-align: center;

	.img {
		display: inline-block;
	}
}

.content {
	width: 1200px;
	margin: 0 auto;
	gap: 40px;
	font-size: 14px;

	.title {
		margin-bottom: 10px;
		font-size: 16px;
		padding: 10px 0;
		color: #fff;
		border-bottom: 1px solid #CB1C1E;
	}

	ul {
		margin: 0;
		padding: 0;
	}

	li {
		display: inline-block;
		width: 50%;
		padding: 10px 0;
		margin-bottom: 10px;
		color: #fff;

		&.line {
			width: 100%;
			margin-bottom: 5px;
			padding: 7px 0;
		}
	}
}

.copyright {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 60px;
	color: #fff;
	font-size: 16px;
	background-color: rgba(0, 0, 0, .2);
}
</style>
<style>
@media (max-width: 750px) {

	/* 底部响应样式 */
	.footer {
		height: auto !important;
		padding: 0px 3% 60px !important;
	}

	.footer div.content {
		width: auto;
		margin-bottom: 10px;
	}

	.footer div.content>div:nth-child(1),
	.footer div.content>div:nth-child(2) {
		display: none;
	}

	.footer div.content>div:nth-child(3) {
		text-align: center;
	}

	.footer div.logo {
		margin-bottom: 30px;
	}

	.footer div.logo img {
		margin-top: 30px;
		height: auto;
	}

	.footer div li {
		padding: 0 !important;
	}

	.copyright {
		padding: 10px 3%;
		height: auto !important;
		text-align: center;
		font-size: 14px !important;
	}
}
</style>