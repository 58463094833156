<template>
  <div class="bg-[#F5F5F5] w-[100%] info-building">
    <div class="adv" :style="style">
      <p class="title">{{ name }}</p>
      <i class="sub">{{ englistName }}</i>
    </div>
    <div
      class="w-[1200px] mx-auto pb-[60px] px-60px box-sizing max-[750px]:w-[100%]"
    >
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup lang="ts">
import useBanner from "@/hooks/useBanner";
import { ref } from "vue";
const name = ref("");
const englistName = ref("");
const style = ref({});
useBanner(function (meneItem) {
  if (!meneItem.banner) return;
  style.value = {
    "background-image": `url(${meneItem.banner})`,
  };
  name.value = meneItem.name;
  meneItem.remark && (englistName.value = meneItem.remark);
});
</script>

<style scoped lang="scss">
.adv {
  padding: 120px 0;
  height: 320px;
  text-align: center;
  background: url("@/assets/images/aboutus.png") center no-repeat;
  background-size: cover;
  .title {
    display: block;
    font-size: 50px;
    font-weight: bold;
    color: #fff;
  }
  .sub {
    font-size: 20px;
    color: #fff;
  }
}
@media (max-width: 750px) {
  .info-building > div.box-sizing div.flex {
    padding-left: 3%;
  }
  .info-building .rounded-sm {
    padding: 30px 3%;
  }
}
</style>
