<template>
  <div class="bg-[#F5F5F5] w-[100%] new-center">
    <div class="adv" :style="style">
      <p class="title">{{ name }}</p>
      <i class="sub">{{ englistName }}</i>
    </div>
    <div class="w-[1200px] mx-auto pb-[60px] px-60px box-sizing page-newlist">
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watchEffect, ref } from "vue";
import { useRouter } from "vue-router";
import useBanner from "@/hooks/useBanner";
const path = ref("");
const name = ref("");
const englistName = ref("");
const router = useRouter();
const style = ref({});
watchEffect(() => {
  path.value = "/" + router.currentRoute.value.path.split("/")[1];
});
useBanner(function (meneItem) {
  if (!meneItem.banner) return;
  style.value = {
    "background-image": `url(${meneItem.banner})`,
  };
  name.value = meneItem.name;
  meneItem.remark && (englistName.value = meneItem.remark);
});
</script>

<style scoped lang="scss">
.adv {
  padding: 120px 0;
  height: 320px;
  text-align: center;
  background: url("@/assets/images/aboutus.png") center no-repeat;
  background-size: cover;
  .title {
    display: block;
    font-size: 50px;
    font-weight: bold;
    color: #fff;
  }
  .sub {
    font-size: 20px;
    color: #fff;
  }
}
@media (max-width: 750px) {
  .page-newlist > div {
    padding-top: 0;
  }
}
</style>
