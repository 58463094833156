import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutUs from "@/views/AboutUs/aboutUs.vue";
import NewsViewVue from "@/views/NewsView.vue";
import JoinUs from "@//views/JoinUs/joinUs.vue";
import PoliciesViewVue from "@/views/PoliciesView.vue";
import PageDetailVue from "@/views/PageDetail.vue";
import NewsCenter from "@/views/NewsCenter/NewsCenter.vue";
import FinancialService from "@/views/FinancialService/financialService.vue";
import FeeStandards from "@/views/FeeStandards/feeStandards.vue";
import PartyBuildingVue from "@/views/PartyBuilding/PartyBuilding.vue";
import BuildingListVue from "@/views/PartyBuilding/BuildingList.vue";
import SecondBuildVue from "@/views/PartyBuilding/SecondBuild.vue";
import AboutDetailVue from "@/views/AboutUs/aboutDetail.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: AboutUs,
  },
  {
    path: '/aboutus/detail',
    name: 'aboutusDetail',
    component: AboutDetailVue
  },
  {
    path: "/financial",
    name: "financial",
    component: FinancialService,
  },
  {
    path: "/news",
    name: "news",
    component: NewsCenter,
    redirect: "/news/list",
    children: [
      {
        path: "/news/list",
        name: "newsList",
        component: NewsViewVue,
      },
      {
        path: "/news/detail",
        name: "newDetail",
        component: PageDetailVue,
      },
    ],
  },
  {
    path: "/joinus",
    name: "joinus",
    component: JoinUs,
  },
  {
    path: "/feestandards",
    name: "feStandards",
    component: FeeStandards,
  },
  {
    path: "/policies",
    name: "policies",
    component: NewsCenter,
    redirect: "/policies/list",
    children: [
      {
        path: "/policies/list",
        name: "policiesList",
        component: PoliciesViewVue,
      },
      {
        path: "/policies/detail",
        name: "policiesDetail",
        component: PageDetailVue,
      },
    ],
  },
  {
    path: '/partyBuilding',
    name: 'PartyBuilding',
    component: PartyBuildingVue,
    redirect: '/partyBuilding/list',
    children: [
      {
        path: '/partyBuilding/list',
        name: 'BuildingList',
        component: BuildingListVue
      },
      {
        path: '/partyBuilding/detail',
        name: 'BuildinDetail',
        component: PageDetailVue
      },
      {
        path: '/partyBuilding/second',
        name: 'BuildinSecond',
        component: SecondBuildVue
      }
    ]
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
