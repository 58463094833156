<template>
    <div class="aboutus page-joinus">
        <div class="adv">
            <p class="title">加入我们</p>
            <i class="sub">JOIN US</i>
        </div>
        <div class="content">
            <div class="path">
                <img class="location" src="@/assets/images/location.png" alt="" srcset="">
                <span class="label">当前位置:</span>
                <span>首页 > 加入我们 > {{ activeName }}</span>
            </div>
            <div class="company rounded">
                <el-tabs v-model="activeName" type="card" class="tabs" @tab-click="handleClick">
                    <el-tab-pane :label="item.title" :name="item.title" v-for="item in list" :key="item.id">
                        <component :is="tabArr[item.title]" :logo="item.logo" :id="item.id" />
                    </el-tab-pane>
                    <!-- <el-tab-pane label="岗位需求" name="岗位需求">
                        <JobRequirements />
                    </el-tab-pane> -->
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref, markRaw } from "vue";
import type { TabsPaneContext } from 'element-plus';
import CorporateCulture from './components/CorporateCulture.vue';
import JobRequirements from './components/JobRequirements.vue';
import useGetNewsNavList from "@/hooks/useGetNewsNavList";
import { IList } from "@/interface/article";
const list = ref<Array<IList>>([])

const tabArr = reactive({
    "企业文化": markRaw(CorporateCulture),
    "岗位需求": markRaw(JobRequirements)
})

const { getNewsInfo } = useGetNewsNavList("/joinus", pullData);
function pullData(res) {
    list.value = res.data.leftList || [];
}
onMounted(() => {
    getNewsInfo();
});
const activeName = ref('企业文化')

const handleClick = (tab: TabsPaneContext, event: Event) => {
    console.log(tab.paneName, event.target)
}
</script>

<style scoped lang="scss">
.aboutus {
    min-height: 100%;
    padding-bottom: 50px;
    background-color: #f7f4f4;
}

.tabs {

    // 优先级高
    :deep(.el-tabs__header) {
        margin-bottom: 60px;
    }

    :global(.el-tabs__nav-scroll) {
        display: flex;
    }

    :global(.el-tabs__nav) {
        margin: 0 auto !important;
        border: none !important;
    }

    :global(.el-tabs__item) {
        width: 160px;
        text-align: center;
        margin-right: 5px;
        border: none !important;
        background-color: #F5F5F5;
    }

    :global(.el-tabs__item.is-active) {
        position: relative;
        color: #fff !important;
        background-color: var(--el-color-primary);
    }

    :global(.el-tabs__item.is-active::before) {
        content: '';
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 6px solid #fff;
    }

    :global(.el-tabs__header) {
        border: none !important;
    }
}

// .tabs {
//     >>> .el-tabs__nav {
//         border: none;
//     }
// }

.adv {
    padding: 120px 0;
    height: 320px;
    text-align: center;
    background: url('@/assets/images/aboutus.png') center no-repeat;
    background-size: cover;

    .title {
        display: block;
        font-size: 50px;
        font-weight: bold;
        color: #fff;
    }

    .sub {
        font-size: 20px;
        color: #fff;
    }
}

.content {
    width: 1200px;
    min-height: 300px;
    margin: 0 auto;

    .company {
        padding: 60px;
        background-color: #fff;
    }

    .path {
        padding: 18px 0;
        color: #666;
        font-size: 14px;

        .location {
            display: inline-block;
            width: 21px;
            height: 26px;
            margin-right: 5px;
            vertical-align: top;
        }

        .label {
            margin-right: 10px;
            color: #333;
            font-size: 16px;
        }
    }
}

@media (max-width: 750px) {
    .page-joinus>div.content {
        width: 100%;
    }

    .page-joinus .adv {
        height: 180px !important;
        background-size: 284% !important;
        padding: 60px 0 10px !important;
    }

    .page-joinus .adv .title {
        font-size: 36px;
    }

    .page-joinus .content>div.path {
        padding: 18px 0 18px 10px;
    }

    .page-joinus .content>div.company {
        padding: 20px 3%;
    }
}
</style>