<template>
  <div class="pt-[60px] w-[100%] company-wrap index-news">
    <div class="flex justify-between items-center">
      <div class="flex items-center max-[750px]:justify-between">
        <div
          v-for="(item, index) in list"
          :key="item.id"
          class="list-item"
          :class="activeIndex === index ? 'active' : ''"
          @click="
            activeIndex = index;
            getSelPage(item.id, 1);
          "
        >
          <i :class="['iconfont !text-[32px]', iconName(item, index)]"></i>
          <span class="ml-[8px]">{{ item.title }}</span>
        </div>
      </div>
      <div class="more-span cursor-pointer relative" @click="goNews">
        查看更多<i class="iconfont icon-jiantou_qiehuanzuo"></i>
      </div>
    </div>
    <div
      class="content w-[100%] box-border"
      :style="`--active-index: ${activeIndex}`"
      v-if="list[activeIndex]"
    >
      <img
        class="absolute w-[100%] top-[100px] rounded-xl h-[420px] object-cover"
        :src="list[activeIndex].logo"
      />
      <div class="new-list flex h-[600px] relative z-10">
        <div class="flex-1 text-white flex pl-[100px] flex-col justify-center">
          <div class="text-[26px] font-bold mb-[16px] leading-[26px]">
            {{ list[activeIndex].title }}
          </div>
          <span class="text-[20px] mb-[16px] leading-[20px]">{{
            list[activeIndex].englistName
          }}</span>
          <span class="block w-[100px] h-[10px] bg-white"></span>
        </div>
        <div class="new-swiper-wrap !pt-[54px] rounded-sm">
          <div
            class="page-turning mr-[12px]"
            v-if="(curList[current] || []).length"
          >
            <span
              @click="changePage(-1)"
              :class="current === 0 ? 'disabled' : ''"
              class="top-arrow"
            >
              <i class="iconfont icon-xiangxiajiantou"></i>
            </span>
            <span
              @click="changePage(1)"
              :class="current === curList.length - 1 ? 'disabled' : ''"
            >
              <i class="iconfont icon-xiangxiajiantou"></i
            ></span>
          </div>
          <div class="list-wrap mr-[35px]">
            <div
              class="list-li flex"
              v-for="(item, index) in curList[current]"
              :key="item.id"
              :class="{ 'mb-[20px]': index <= curList.length }"
              @click="goNewsDetail(item)"
            >
              <div class="bg-white w-[584px] p-[20px] left-wrap flex">
                <i
                  class="iconfont icon-yuanquan point mr-[4px] flex-shrink-0 !text-[20px] mt-[-7px]"
                ></i>
                <div class="news-box flex-1">
                  <div
                    class="new-title text-[15px] leading-[15px] text-[#333333] flex items-center"
                  >
                    <div
                      class="flex-1 w-[534px] pr-[8px] whitespace-nowrap text-ellipsis overflow-hidden"
                    >
                      {{ item.title }}
                    </div>
                    <span class="text-[12px] text-[#666666] date-span">{{
                      item.date
                    }}</span>
                  </div>
                  <p
                    class="new-content w-[534px] text-[13px] text-[#666666] leading-[22px] mt-[8px]"
                  >
                    {{ item.title }}
                  </p>
                </div>
              </div>
              <div
                class="right-row w-[60px] h-[100%] invisible flex-shrink-0 pt-[10px] pl-[15px]"
              >
                <i class="iconfont icon-xiangyou-jiantou !text-[24px]"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import { chunk } from "lodash";
import useGetNewsNavList from "@/hooks/useGetNewsNavList";
import useNavigation from "@/hooks/useNavigation";
import { getPage } from "@/http/api";
const { push } = useNavigation();
function goNews() {
  push("/news");
}
interface IList {
  id: number;
  logo: string;
  icon?: string;
  title: string;
  englistName: string;
  children?: Array<IItem>;
}
interface IItem {
  title: string;
  desc: string;
  date: string;
  id: string | number;
  type: string;
}
const activeIndex = ref(0);
const list = ref<Array<IList>>([]);
const curList = computed(() => {
  return chunk(list.value[activeIndex.value].children || [], 4);
});

const current = ref(0);
const changePage = (num: number) => {
  if (current.value + num < 0 || current.value + num >= curList.value.length) {
    return;
  }
  current.value += num;
};
const iconName = computed(() => {
  return function (item: IList, index) {
    if (item.icon) {
      return item.icon;
    } else {
      return ["icon-guangbo", "icon-xinwen_o", "icon-xinxi"][index] || "";
    }
  };
});
// 如果顶部的tab修改了，重置下分页
watch(
  () => activeIndex.value,
  () => {
    current.value = 0;
  }
);
function pullData(res) {
  console.log(res, "res=====");
  list.value = res.data.leftList || [];
  if (!list.value.length) return;
  getSelPage(list.value[0].id, 1);
}
const { getNewsInfo } = useGetNewsNavList("/news", pullData);
onMounted(() => {
  getNewsInfo();
});
interface IResult {
  data: Array<IList>;
}
function getSelPage(menuId, pageNum) {
  getPage<IResult>({
    menuId,
    pageNum,
    pageSize: 20,
  }).then((res) => {
    list.value[activeIndex.value].children = (res.rows as any) || [];
  });
}
function goNewsDetail(item: IItem) {
  push({
    path: "/news/detail",
    query: {
      id: item.id,
    },
  });
}
</script>
<style lang="scss" scoped>
.new-list {
  $row-width: 6px;
  // &::before {
  //   content: "";
  //   display: block;
  //   width: 0;
  //   height: 0;
  //   border-top: $row-width solid #fff;
  //   border-left: $row-width solid #fff;
  //   border-bottom: $row-width solid transparent;
  //   border-right: $row-width solid transparent;
  //   transform: rotate(45deg);
  //   left: calc(var(--active-index) * 136px + 60px);
  //   top: -5px;
  //   @apply absolute;
  // }
}
.list-item {
  &:hover,
  &.active {
    background-color: var(--el-color-primary);
    color: #fff;
    &.active {
      $row-width: 6px;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: $row-width solid #fff;
        border-left: $row-width solid #fff;
        border-bottom: $row-width solid transparent;
        border-right: $row-width solid transparent;
        transform: rotate(45deg) translateX(-50%);
        left: 50%;
        bottom: -11px;
        @apply absolute;
      }
    }
  }

  @apply cursor-pointer pl-[16px] pr-[25px] py-[10px] flex items-center mr-[24px] bg-[#ccc] rounded-md max-[750px]:py-0;
}
.more-span {
  padding-right: 60px;
  font-size: 14px;
  &:hover {
    color: var(--el-color-primary);
  }
  .iconfont {
    position: absolute;
    right: 0;
    bottom: -50px;
    font-size: 50px;
    transform: rotateY(-180deg);
  }
}
.content {
  @apply relative;

  .new-swiper-wrap {
    @apply pl-[20px]  rounded-sm  min-h-[250px] relative flex;

    .page-turning {
      align-self: flex-end;
      margin-bottom: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        display: inline-block;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 2px;
        cursor: pointer;

        &.disabled {
          background: rgba(255, 255, 255, 0.5);
          cursor: not-allowed;
        }

        &:last-child {
          margin-top: 8px;
        }
      }

      .top-arrow {
        transform: rotate(-180deg);
      }
    }
  }
}
@mixin boxShaow() {
  box-shadow: 0 0 10px #999;
}
.list-li {
  cursor: pointer;
  .left-wrap {
    @include boxShaow();
    border-radius: 2px;

    .new-content {
      display: -webkit-box; /* 将文本显示为块元素 */
      -webkit-line-clamp: 2; /* 设置最大显示行数为2 */
      -webkit-box-orient: vertical; /* 设置块元素内部文本垂直排列 */
      overflow: hidden; /* 当文本溢出块元素时进行隐藏 */
      text-overflow: ellipsis; /* 溢出部分显示省略号 */
    }
  }

  &:hover {
    background-color: var(--el-color-primary);
    border-radius: 2px;
    @include boxShaow();
    .left-wrap {
      background: var(--el-color-primary);
      color: #fff !important;
      box-shadow: none;
      border-radius: none;
    }
    .news-box * {
      color: #fff;
    }

    .date-span {
      display: none;
    }

    .right-row {
      visibility: visible;
      color: #fff;
    }
  }
}
@media (max-width: 750px) {
  .home > div:first-child {
    height: auto;
  }
  .index-news {
    min-height: 580px;
  }
  .index-news .more-span {
    display: none;
  }
  .index-news > div:first-child {
    width: 100%;
    margin: 0 auto;
  }
  .index-news > div:first-child > div:first-child {
    width: 94%;
    margin: 0 auto;
  }
  .index-news > div:first-child > div:first-child div.list-item {
    margin-right: 3px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .index-news > div:first-child > div:first-child div.list-item span {
    margin-left: 5px;
  }
  .index-news > div:last-child > img {
    height: 360px;
  }
  .index-news > div:last-child > div.new-list {
    height: 360px;
  }
  .index-news > div:last-child > div.new-list > div.text-white {
    display: none;
  }
  .index-news > div:last-child > div.new-list .new-swiper-wrap {
    width: 100%;
    padding: 0 3%;
  }
  .index-news > div:last-child > div.new-list .new-swiper-wrap .page-turning {
    position: absolute;
    left: 5px;
    bottom: 40px;
    margin-bottom: 0;
  }
  .index-news > div:last-child > div.new-list .new-swiper-wrap .list-wrap {
    width: calc(100% - 40px);
    margin-right: 0;
    margin-left: 40px;
  }
  .index-news
    > div:last-child
    > div.new-list
    .new-swiper-wrap
    .list-wrap
    .list-li
    .left-wrap {
    width: 100%;
    padding: 20px 10px;
  }
  .index-news
    > div:last-child
    > div.new-list
    .new-swiper-wrap
    .list-wrap
    .list-li
    .left-wrap
    i {
    margin-top: -4px;
    margin-left: -5px;
  }
  .index-news
    > div:last-child
    > div.new-list
    .new-swiper-wrap
    .list-wrap
    .list-li
    .left-wrap
    .news-box {
    width: 96%;
  }
  .index-news
    > div:last-child
    > div.new-list
    .new-swiper-wrap
    .list-wrap
    .list-li
    .left-wrap
    .news-box
    p {
    width: 100%;
  }
}
</style>
