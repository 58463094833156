<template>
  <div class="w-[1200px] mx-auto py-[60px] px-60px box-sizing webzcfg">
    <div class="flex py-[20px] text-[14px] text-[#606266]">
      <div class="path">
        <img
          class="location"
          src="@/assets/images/location.png"
          alt=""
          srcset=""
        />
        <span class="label">当前位置:</span>
        <span><el-link @click="push('/')">首页</el-link> > {{ name }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import PageList from "@/components/PageList/ListComp.vue";
import { defineOptions, ref, watch } from "vue";
import useNavigation from "@/hooks/useNavigation";
const { push } = useNavigation();
import useCurName from "@/hooks/useCurMenu";
const { name } = useCurName("/policies");
function goDetail({ id }) {
  push({
    path: "/policies/detail",
    query: {
      id,
    },
  });
}
defineOptions({
  name: "PoliciesView",
});
</script>

<style scoped lang="scss">
.path {
  padding: 18px 0;
  color: #666;
  font-size: 14px;

  .location {
    display: inline-block;
    width: 21px;
    height: 26px;
    margin-right: 5px;
    vertical-align: top;
  }

  .label {
    margin-right: 10px;
    color: #333;
    font-size: 16px;
  }
}
</style>
