<template>
  <div class="mt-[80px] relative yg-wrap">
    <title-comp title="员工风采"></title-comp>
    <template v-if="list.length">
      <swiper
        :modules="modules"
        :slides-per-view="slidesPerView"
        :centeredSlides="centeredSlides"
        :loop="true"
        autoplay
        :style="{ height: isMobile ? 'auto' : '400px' }"
        :watchSlidesProgress="watchSlidesProgress"
        @setTranslate="setTranslate"
        @setTransition="setTransition"
        :navigation="navigation"
        :scrollbar="{ draggable: false }"
        class="swiperBox"
        @slideChange="onSlideChange"
      >
        <swiper-slide
          v-for="item in list"
          :key="item"
          @click="goNewsDetail(item)"
          class="cursor-pointer"
        >
          <div class="content-wrap w-[100%]">
            <img class="w-[100%]" :src="item.logo" />
            <div
              class="swiper-container-text"
              :class="{ 'is-mobile': isMobile }"
            >
              <div class="title">员工风采</div>
              <p class="desc mt-[20px] mb-[30px] px-[32px]">
                {{ item.remark }}
              </p>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </template>
    <div class="h-[400px] w-[100%]" v-else></div>
    <div class="swiper-button-prev" @clik.stop="prevEl" />
    <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
    <div class="swiper-button-next" @clik.stop="nextEl" />
  </div>
</template>
<script lang="ts" setup>
import { nextTick, reactive, ref, toRefs } from "vue";
import "swiper/css/pagination"; // 轮播图底面的小圆点
import "swiper/css/navigation"; // 轮播图两边的左右箭头
import TitleComp from "../HomeComp/titleComp.vue";
import { Autoplay, Navigation, Scrollbar } from "swiper";
import { getConfig } from "@/http/api/index";
import useNavigation from "@/hooks/useNavigation";
import useGetDevice from "@/hooks/useGetDevice";
const { push } = useNavigation();
const navigation = ref({
  nextEl: ".swiper-button-next",
  prevEl: ".swiper-button-prev",
});
// 在modules加入要使用的模块
const modules = [Autoplay, Navigation, Scrollbar];
const prevEl = (item, index) => {
  console.log("上一张" + index + item);
};

const {
  versions: { isMobile },
} = useGetDevice();
const swiperConfig = reactive({
  centeredSlides: !isMobile,
  slidesPerView: isMobile ? 1 : 5,
  watchSlidesProgress: !isMobile,
});
const { centeredSlides, slidesPerView, watchSlidesProgress } =
  toRefs(swiperConfig);

const nextEl = () => {
  // console.log('下一张')
};
// 更改当前活动swiper
let changeTimer: any = null;
// 更改当前活动swiper
const onSlideChange = (swiper) => {
  if (isMobile) return;
  // swiper是当前轮播的对象，里面可以获取到当前swiper的所有信息，当前索引是activeIndex
  changeTimer && clearTimeout(changeTimer);
  changeTimer = setTimeout(() => {
    nextTick(() => {
      const slides = swiper.slides;
      for (let i = 0; i < slides.length; i++) {
        const slide = slides.eq(i);
        slide[0].classList.remove("swiper-slide-prev-border");
        if (slide[0].classList.contains("swiper-slide-prev")) {
          slide[0].previousSibling.classList.add("swiper-slide-prev-border");
        }
      }
    });
  }, 50);
};

function setTransition(swiper, transition) {
  if (isMobile) return;
  for (let i = 0; i < swiper.slides.length; i++) {
    let slide = swiper.slides.eq(i);
    slide.transition(transition);
  }
}
function setTranslate(swiper) {
  if (isMobile) return;
  const slides = swiper.slides;
  for (let i = 0; i < slides.length; i++) {
    const slide = slides.eq(i);
    const progress = slides[i].progress;
    slide[0].children[0].setAttribute(
      "style",
      `transform: scale(${1 - Math.abs(progress) / 8})`
    );
  }
}
function goNewsDetail(item: IList) {
  push({
    path: "/news/detail",
    query: {
      id: item.articleId,
    },
  });
}
interface IList {
  logo: string;
  title: string;
  remark: string;
  skin: string | unknown;
  articleId: string;
}
const list = ref<Array<IList>>([]);
function getSliderList() {
  getConfig().then((res: any) => {
    list.value = res.data as any;
  });
}
getSliderList();
</script>

<style scoped lang="scss">
.swiperBox {
  padding: 0 !important;
}
:deep() {
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition-property: all;

    .content-wrap {
      box-shadow: 0 0 8px #ccc;
    }
  }

  .swiper-slide-active .swiper-container-text {
    display: block;
  }

  .swiper-container-text {
    display: none;
    &.is-mobile {
      display: block;
    }
    transition: transform 0.4s;
    .title {
      margin-top: 19px;
      // margin-block: 10px;
      color: var(--el-color-primary);
      text-align: center;
      position: relative;

      &::before {
        display: block;
        content: "";
        width: 60px;
        height: 3px;
        background: var(--el-color-primary);
        position: absolute;
        bottom: -9px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .desc {
      height: 66px;
      font-size: 13px;
      line-height: 22px;
      display: -webkit-box; /* 将文本显示为块元素 */
      -webkit-line-clamp: 3; /* 设置最大显示行数为2 */
      -webkit-box-orient: vertical; /* 设置块元素内部文本垂直排列 */
      overflow: hidden; /* 当文本溢出块元素时进行隐藏 */
      text-overflow: ellipsis; /* 溢出部分显示省略号 */
    }
  }
  .swiper-slide.swiper-slide-prev-border {
    .content-wrap {
      transform-origin: right;
    }
  }
  .swiper-slide-next + .swiper-slide {
    .content-wrap {
      transform-origin: left;
    }
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    color: var(--el-color-primary) !important ;
    font-size: 16px !important;
  }
  .swiper-button-prev {
    position: absolute;
    left: 20px !important;
    top: 50% !important;
    transform: translateY(-50%);
    background-color: transparent !important;
    width: 30px !important;
    height: 30px !important;
    line-height: 30px;
    text-align: center;
    border: 1px solid var(--el-color-primary);
    border-radius: 50% !important;
  }
  .swiper-button-next {
    position: absolute;
    right: 20px !important;
    top: 50% !important;
    transform: translateY(-50%);
    background-color: transparent !important;
    width: 30px !important;
    height: 30px !important;
    line-height: 30px;
    text-align: center;
    border: 1px solid var(--el-color-primary);
    border-radius: 50% !important;
  }
}
</style>
