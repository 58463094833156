<template>
  <div class="aboutus page-abouts">
    <AdvComp />
    <div class="content">
      <div class="path">
        <img class="location" src="@/assets/images/location.png" alt="" srcset="" />
        <span class="label">当前位置:</span>
        <span>首页 > 关于我们 > {{ activeName }}</span>
      </div>
      <div class="company rounded">
        <el-tabs v-model="activeName" type="card" class="tabs" @tab-click="handleClick">
          <!-- <el-tab-pane label="公司背景" name="公司背景">
            <CompanyBackground :dataList="companyBgList" />
          </el-tab-pane> -->
          <el-tab-pane label="公司概况" name="公司概况">
            <CompanyIntro :dataList="companyIntroList" />
          </el-tab-pane>
          <el-tab-pane label="大事记" name="大事记">
            <DevelopmentHistory :dataList="developmentHistoryList" />
          </el-tab-pane>
          <el-tab-pane label="资质荣誉" name="资质荣誉">
            <DevelopmentHistory :dataList="qualifiyHonorsList" />
          </el-tab-pane>
          <el-tab-pane label="信息公开" name="信息公开">
            <InformationDisclosure :dataList="informationDisclosureList" />
          </el-tab-pane>
          <el-tab-pane label="联系我们" name="联系我们">
            <ContactUs :dataList="contactUs" :qrCodesObj="qrCodesObj" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted, watch, nextTick } from "vue";
import type { TabsPaneContext } from "element-plus";
// import CompanyBackground from "./components/CompanyBackground.vue";
import CompanyIntro from "./components/CompanyIntro.vue";
import DevelopmentHistory from "./components/DevelopmentHistory.vue";
// import QualifiyHonors from "./components/QualifiyHonors.vue";
import InformationDisclosure from "./components/InformationDisclosure.vue";
import AdvComp from "./components/AdvComp.vue";
import ContactUs from "./components/ContactUs.vue";
import { getLeaf, getPage } from "@/http/api/index";
import useGetContext from "@/hooks/useGetContext";
import { IItem } from "@/interface/article.d";
import useGetQRCodes from "@/hooks/useGetQRCodes";
import { useRouter } from "vue-router";

const { proxy, getMenuId } = useGetContext();
const { qrCodesObj } = useGetQRCodes();
const router = useRouter();
const tabMaps = {
  background: "公司背景",
  intro: "公司概况",
  honors: "资质荣誉",
};

const activeName = ref("公司概况");

const companyBgList = ref<IItem[]>([]);
const companyIntroList = ref<IItem[]>([]);
const developmentHistoryList = ref<IItem[]>([]);
const qualifiyHonorsList = ref<IItem[]>([]);
const contactUs = ref<IItem[]>([]);
const informationDisclosureList = ref<IItem[]>([]);

const tabList = ref<IItem[]>([]);

const handleClick = (tab: TabsPaneContext, event: Event) => {
  handleTabChange(tab.paneName);
};

function getNewsInfo() {
  const { newMenu } = getMenuId("/aboutus") || {};
  getLeaf({ menuId: newMenu.id }).then((res: any) => {
    if (res.code === 200) {
      const list = res.data.leftList;
      tabList.value = list;
      const tarValue = list.find((item) => item.title === activeName.value);
      getSelPage(tarValue.id, 1);
    }
  });
}
onMounted(() => {
  const { newMenu } = getMenuId("/aboutus") || {};
  const tab = router.currentRoute.value.query?.tab as string;
  if (tab) {
    activeName.value = tabMaps[tab] || "公司背景";
  }
  if (newMenu?.id) {
    getNewsInfo();
  } else {
    proxy.$eventBus.on("navMenuInit", getNewsInfo);
  }
});
onUnmounted(() => {
  proxy.$eventBus.off("navMenuInit", getNewsInfo);
});

const handleTabChange = (name) => {
  const tarValue = tabList.value.find((item) => item.title === name);
  if(tarValue?.title === '信息公开') {
    const arr = tarValue.leftList?.map(item => item.id);
    const apiArr = arr.map(item => getPage({
      menuId: item,
      pageNum: 1,
      pageSize: 20,
    }))
    handleInfoDisclosure(apiArr, tarValue.leftList);
    return
  }
  if (tarValue?.id) {
    getSelPage(tarValue?.id, 1);
  }
};
const handleInfoDisclosure = (arr, infoList) => {
  Promise.all(arr).then((res) => {
    const list: any = res.map((item, index) => {
      return {
        title: infoList[index].title,
        list: item.rows,
      };
    });
    informationDisclosureList.value = list;
  });
};

const getSelPage = (menuId, pageNum) => {
  getPage({
    menuId,
    pageNum,
    pageSize: 20,
  }).then((res: any) => {
    if (res.code === 200) {
      switch (activeName.value) {
        case "公司背景":
          companyBgList.value = res.rows;
          break;
        case "公司概况":
          companyIntroList.value = res.rows;
          break;
        case "大事记":
          developmentHistoryList.value = res.rows;
          break;
        case "资质荣誉":
          qualifiyHonorsList.value = res.rows;
          break;
        case "联系我们":
          contactUs.value = res.rows;
          break;
        // case "信息公开":
        //   informationDisclosureList.value = res.rows;
        //   break;
      }
    }
  });
};

watch(
  () => router.currentRoute.value,
  () => {
    nextTick(() => {
      const tabCur = router.currentRoute.value.query?.tab as string;
      const tabIndex = tabMaps[tabCur] || "公司背景";
      activeName.value = tabIndex;
      handleTabChange(tabIndex);
    });
  }
);
</script>

<style scoped lang="scss">
.aboutus {
  min-height: 100%;
  padding-bottom: 50px;
  background-color: #f7f4f4;
}

.tabs {

  // 优先级高
  :deep(.el-tabs__header) {
    margin-bottom: 60px;
  }

  :global(.el-tabs__nav-scroll) {
    display: flex;
  }

  :global(.el-tabs__nav) {
    margin: 0 auto !important;
    border: none !important;
  }

  :global(.el-tabs__item) {
    width: 160px;
    text-align: center;
    margin-right: 5px;
    border: none !important;
    background-color: #f5f5f5;
  }

  :global(.el-tabs__item.is-active) {
    position: relative;
    color: #fff !important;
    background-color: var(--el-color-primary);
  }

  :global(.el-tabs__item.is-active::before) {
    content: "";
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 6px solid #fff;
  }

  :global(.el-tabs__header) {
    border: none !important;
  }
}

// .tabs {
//     >>> .el-tabs__nav {
//         border: none;
//     }
// }
.content {
  width: 1200px;
  min-height: 300px;
  margin: 0 auto;

  .company {
    padding: 60px;
    background-color: #fff;
  }

  .path {
    padding: 18px 0;
    color: #666;
    font-size: 14px;

    .location {
      display: inline-block;
      width: 21px;
      height: 26px;
      margin-right: 5px;
      vertical-align: top;
    }

    .label {
      margin-right: 10px;
      color: #333;
      font-size: 16px;
    }
  }
}

@media (max-width: 750px) {
  .page-abouts>div.content {
    width: 100%;
  }

  .page-abouts .adv {
    height: 180px !important;
    background-size: 284% !important;
    padding: 60px 0 10px !important;
  }

  .page-abouts .adv .title {
    font-size: 36px;
  }

  .page-abouts .content>div.path {
    padding: 18px 0 18px 10px;
  }

  .page-abouts .content>div.company {
    padding: 20px 3%;
  }

}

:deep(.el-tabs__content) {
  overflow: unset;
}
</style>
