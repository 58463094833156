<template>
  <div class="w-[1200px] mx-auto pb-[20px] px-60px box-sizing building-list page-building">
    <div class="flex py-[20px] text-[14px] text-[#606266]">
      <div class="path">
        <img class="location" src="@/assets/images/location.png" alt="" srcset="" />
        <span class="label">当前位置:</span>
        <span><el-link to="/" @click="push('/')">首页</el-link> > {{ name }}
        </span>
      </div>
    </div>
    <div class="bg-white pt-[40px]" v-loading="Loading">
      <div class="flex p-[60px] building-impor-wrap" v-if="false">
        <div class="w-50% relative building-impor-gg" @click="goDetail(leftList[activeIndex])">
          <img class="w-[100%]" :src="leftList[activeIndex]?.banner || gqbanner" />
          <div class="w-[100%] bg-black/50 absolute z-10 bottom-0 h-[50px] text-white flex items-center justify-between">
            <i class="iconfont icon-xiangxiajiantou transform rotate-90 cursor-pointer ml-[8px]"
              @click.stop="changeIndex(-1)"></i>
            <span class="flex items-end justify-center overflow-hidden pb-[6px]">
              <span class="text-[20px] text-[var(--el-color-primary)]">{{
                activeIndex + 1
              }}</span>/<span class="mr-[8px]">{{ leftList.length }}</span><span
                class="inline-block overflow-hidden text-ellipsis whitespace-nowrap">{{ leftList[activeIndex]?.title
                }}</span>
            </span>

            <i class="iconfont icon-xiangxiajiantou transform rotate-[-90deg] cursor-pointer mr-[8px]"
              @click.stop="changeIndex(1)"></i>
          </div>
        </div>
        <div class="w-[50%] ml-[40px] building-impor-typewrap">
          <div v-for="(item, index) in rightList" :key="item.id">
            <p class="text-[20px] text-[#333] leading-[20px] text-ellipsis overflow-hidden whitespace-nowrap">
              {{ item.title }}
            </p>
            <ul class="mt-[26px]">
              <li class="doc" v-for="demo in item.children" :key="demo.id" @click="goDetail(demo)">
                <span class="flex-1 overflow-hidden whitespace-nowrap text-ellipsis">{{ demo.title }}</span>
              </li>
            </ul>
            <el-divider v-if="index != rightList.length - 1" />
          </div>
        </div>
      </div>
      <div v-if="false"
        class=" building-impor-loog mb-[60px] w-[1080px] h-[97px] bg-[url('@/assets/images/center.png')] bg-auto mx-auto">
      </div>
      <div class="flex flex-wrap pb-[20px] px-[60px] justify-between building-impor-list">
        <div v-for="item in typeList" :key="item.id" class="list-li pb-[60px]">
          <p class="text-ellipsis overflow-hidden whitespace-nowrap text-[18px] cursor-pointer leading-[18px] text-[var(--el-color-primary)] pb-[12px] border-b-[1px]"
            @click="goSecond(item)">
            {{ item.title }}
          </p>
          <ul class="mt-[26px]">
            <li class="doc" v-for="demo in item.children" :key="demo.id" @click="goDetail(demo)">
              <span class="flex-1 overflow-hidden whitespace-nowrap text-ellipsis">{{ demo.title }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import useNavigation from "@/hooks/useNavigation";
import { getPage } from "@/http/api/index";
import gqbanner from "@/assets/images/gqbanner.png";
const activeIndex = ref(0);
const Loading = ref(false)
function changeIndex(num) {
  activeIndex.value += num;
  if (activeIndex.value < 0) {
    activeIndex.value = leftList.value.length - 1;
  } else if (activeIndex.value >= leftList.value.length) {
    activeIndex.value = 0;
  }
}
interface IRightItem {
  title: string;
  id: string;
  banner: string;
  children?: Array<IRightItem>;
}
const typeList = ref<Array<IRightItem>>([]);
// 轮播数据
const leftList = ref<Array<IRightItem>>([]);
const rightList = ref<Array<IRightItem>>([]);
const { push } = useNavigation();
import useCurName from "@/hooks/useCurMenu";
const { name } = useCurName("/partyBuilding");
function goSecond({ id }) {
  push({
    path: "/partyBuilding/second",
    query: {
      id,
    },
  });
}
import useGetNewsNavList from "@/hooks/useGetNewsNavList";
const { getNewsInfo } = useGetNewsNavList<{ type: string }>(
  "/partyBuilding",
  pullData,
  [
    // {
    //   type: "0",
    // },
    {
      type: "1",
    },
  ]
);
function pullData(res) {
  // const topList = res.data[0].data.leftList;
  // if (topList.length > 0) {
  //   getPage({ pageNum: 1, pageSize: 3, menuId: topList[0].id }).then((res) => {
  //     leftList.value = res.rows;
  //   });
  //   rightList.value = topList.slice(1, 3);
  //   Promise.all(
  //     rightList.value.map((item) =>
  //       getPage({ pageNum: 1, pageSize: 3, menuId: item.id })
  //     )
  //   ).then((res) => {
  //     rightList.value.forEach((item, index) => {
  //       item.children = res[index].rows;
  //       console.log(res[index]);
  //     });
  //   });
  // }

  // typeList.value = res.data[1].data.leftList || [];
  Loading.value = true
  typeList.value = res.data[0].data.leftList || [];
  Promise.all(
    typeList.value.map((item) =>
      getPage({ pageNum: 1, pageSize: 4, menuId: item.id })
    )
  ).then((res) => {
    typeList.value.forEach((item, index) => {
      item.children = res[index].rows;
    });
  }).finally(() => {
    Loading.value = false
  });
}

function goDetail({ id }) {
  push({
    path: "/partyBuilding/detail",
    query: {
      id,
    },
  });
}
onMounted(() => {
  getNewsInfo();
});
</script>

<style scoped lang="scss">
.active {
  color: var(--el-color-primary);
}

.doc {
  display: flex;
  align-items: center;
  @apply flex-1 text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer text-[#666] text-[16px] leading-[20px] pb-[16px] hover:text-[var(--el-color-primary)];

  &::before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--el-color-primary);
    margin-right: 8px;
    flex-shrink: 0;
  }
}

.list-li {
  width: calc(50% - 20px);
}

.path {
  padding: 18px 0;
  color: #666;
  font-size: 14px;

  .location {
    display: inline-block;
    width: 21px;
    height: 26px;
    margin-right: 5px;
    vertical-align: top;
  }

  .label {
    margin-right: 10px;
    color: #333;
    font-size: 16px;
  }
}

@media (max-width: 750px) {
  .page-building>div:first-child {
    padding: 0 0 0 3%;
  }

  .page-building>div:last-child>div:first-child {
    padding: 20px 3%;
    flex-flow: column wrap;
  }

  .page-building>div:last-child>div:first-child>div:last-child {
    width: 100%;
    margin: 15px 0 0;
  }

  .page-building>div:nth-child(2) div.building-impor-loog {
    width: 100%;
    background-size: 100%;
    height: 33px;
    background-repeat: no-repeat;
  }

  .page-building>div:nth-child(2) div.building-impor-list {
    padding: 0 3%;
    flex-flow: column wrap;
  }

  .page-building>div:nth-child(2) div.building-impor-list>div {
    width: 100%;
  }

  .page-building>div:nth-child(2) div.building-impor-list>div>p {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
@/hooks/useCurMenu
