<template>
  <div class="bg-white pt-[60px] building-second-wrap page-building-list">
    <div class="flex items-center justify-center building-second-nav">
      <div
        v-for="(item, index) in leafList"
        :key="item.id"
        class="list-item"
        :class="activeIndex === index ? 'active' : ''"
        @click="changeActive(index)"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="content w-[100%] box-border pt-[60px]">
      <div class="new-list relative z-10">
        <ul class="px-[60px] w-[100%]">
          <li
            class="doc flex cursor-pointer text-[#666] text-[16px] leading-[20px] pb-[16px] hover:text-[var(--el-color-primary)]"
            v-for="demo in dataList"
            :key="demo.id"
            @click="goDetail(demo)"
          >
            <span
              class="flex-1 whitespace-nowrap text-ellipsis overflow-hidden pr-[14px]"
              >{{ demo.title }}</span
            >
            <span
              class="whitespace-nowrap flex-shrink-0 text-[#999] text-[14px]"
              >{{ demo.creatTime }}</span
            >
          </li>
        </ul>
        <div
          class="flex justify-center pt-[99px] pb-[73px] building-second-pagination"
        >
          <el-pagination
            prev-text="上一页"
            next-text="下一页"
            background
            layout="prev, pager, next"
            :current-page="pagination.pageNum"
            :total="pagination.total"
            :page-size="pagination.pageSize"
            @current-change="currentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";
import { IPagination } from "@/interface/index";
import useNavigation from "@/hooks/useNavigation";
export interface IListItem {
  id: string;
  title: string;
  banner?: string;
  content?: string;
  digest?: string;
  children?: Array<IListItem>;
}
interface IItem {
  title: string;
  id: string;
  creatTime?: string;
  children?: Array<IItem>;
}
const dataList = ref<Array<IItem>>([]);
const leafList = ref<Array<IItem>>([]);
const activeIndex = ref(0);
const { push, router } = useNavigation();
function changeActive(index) {
  activeIndex.value = index;
  currentChange(1);
}
const pagination = reactive<IPagination>({
  total: 0,
  pageSize: 15,
  pageNum: 1,
});

function currentChange(pageNum = 1) {
  pagination.pageNum = pageNum;
  getPageFunc(leafList.value[activeIndex.value].id);
}

function goDetail({ id }) {
  push({
    path: "/partyBuilding/detail",
    query: {
      id,
    },
  });
}

import useGetNewsNavList from "@/hooks/useGetNewsNavList";
import { getPage } from "@/http/api";
const { getNewsInfo } = useGetNewsNavList<{ type: string }>(
  "/partyBuilding",
  pullData,
  [{ type: "1" }]
);
function pullData(res) {
  leafList.value = res.data[0].data.leftList || [];
  const { id } = router.currentRoute.value.query;
  if (id) {
    activeIndex.value = leafList.value.findIndex((v) => v.id == id);
  }
  getPageFunc(id || leafList.value[activeIndex.value].id);
}
function getPageFunc(menuId) {
  getPage({
    pageNum: pagination.pageNum,
    pageSize: pagination.pageSize,
    menuId,
  }).then((res) => {
    pagination.total = res.total || 0;
    dataList.value = res.rows || [];
  });
}
onMounted(() => {
  getNewsInfo();
});
</script>

<style scoped lang="scss">
.list-item {
  &:hover,
  &.active {
    background-color: var(--el-color-primary);
    color: #fff;
  }
  &.active {
    $row-width: 6px;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-top: $row-width solid #fff;
      border-left: $row-width solid #fff;
      border-bottom: $row-width solid transparent;
      border-right: $row-width solid transparent;
      transform: rotate(45deg) translateX(-50%);
      left: 50%;
      bottom: -11px;
      @apply absolute;
    }
  }
  @apply cursor-pointer pl-[16px] pr-[25px] py-[10px] flex items-center mr-[24px] bg-[#ccc] rounded-md;
}
.content {
  @apply relative;

  .new-list {
    $row-width: 5px;
  }
}
@mixin boxShaow() {
  box-shadow: 0 0 10px #999;
}
.doc {
  display: flex;
  align-items: center;
  &::before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--el-color-primary);
    margin-right: 8px;
  }
}
@media (max-width: 750px) {
  .page-building-list > div:first-child {
    text-align: center;
  }
  .page-building-list > div:first-child > div {
    display: block;
    width: 24%;
    padding: 10px 0;
    margin: 0 5px;
  }
  .page-building-list > div:last-child ul {
    padding: 0 3%;
  }
  .page-building-list > div:last-child div.building-second-pagination {
    padding-top: 40px;
  }
}
</style>
