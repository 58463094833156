<template>
  <div class="mt-[100px] index-abouts">
    <TitleComp title="关于广晟财务公司" />
    <div class="flex flex-wrap h-[1200px] w-[100%] about-copany">
      <div class="mr-[50%] w-[50%] h-[600px] pr-[120px] flex justify-end bg-[#F5F5F5]">
        <div class="w-[480px] pt-[101px]">
          <div class="about-title">公司介绍</div>
          <div class="text-[14px] leading-[22px] mt-[28px] indent-[2em]">
            广东省广晟财务有限公司（GuangdongRising Finance Co.,
            Ltd.）成立于2015年6月，是经原中国银保监会批准成立的非银行金融机构，注册资本由广东省广晟控股集团有限公司（原广东省广晟资产经营有限公司，以下简称“广晟集团”）、广晟集团下属的成员单位深圳市中金岭南有色金属股份有限公司和佛山市国星光电股份有限公司分别出资人民币10亿元、1亿元和0.3亿元入股财务公司，是广晟集团打造金融板块的重要组成部分，是省属企业中的第三家财务公司
            <br />
            <br />
            <div class="indent-[2em]">
              广晟财务公司以“服务和促进集团公司的战略发展”为宗旨，以提升广晟控股综合竞争力为目的，致力于打造广晟控股内部专业的资金集中管理平台、资金风险防控平台、价值创造平台、融资营运平台和综合金融服务平台，积极发挥在“大广晟、大财务、大资金”战略型财务管控体系中的重要作用。
            </div>
          </div>
          <el-button class="mt-[34px]" @click="goAboutUs">查看更多
            <i class="ml-[40px] iconfont rotate-[-90deg] icon-xiangxiajiantou"></i></el-button>
        </div>
      </div>
      <div class="ml-[50%] w-[50%] h-[600px] flex justify-start bg-[#F5F5F5]">
        <div class="w-[600px] pt-[101px] h-[100%] bgimage bg-cover bg-center">
          <!-- <div class="about-title">企业文化</div>
          <div class="text-[14px] leading-[22px] mt-[28px]">
            <span class="block"
              >广晟控股集团坚持以习近平新时代中国特色社会主义思想为指导，深入学习贯彻习近平总书记关于国资国企的重要论述和对广东系列重要讲话、重要指示批示精神，坚决贯彻落实省委省政府及省国资委的决策部署，着力将主业向资源性、引领性行业聚焦，企业发展质量不断提高、产业结构不断优化、抗风险能力不断提升，成为省属企业中控股上市公司数量最多、科技创新能力强、主业实业优势突出的企业。</span
            >
            <span class="block mt-[22px]"
              >截至2022年底，广晟控股集团共有高新技术企业68家，拥有国家级和省级研发机构75个，其中院士工作站4个、博士博士后工作站8个；累计授权专利4426项，其中发明专利953项；累计获得省部级以上奖项210项，其中国家级奖项16项；多项创新指标持续稳居省属企业第一。</span
            >
          </div>
          <div
            class="w-[348px] flex flex-col text-[#333] text-[30px] mx-auto mt-[57px] kh"
          >
            <span class="w-[100%]">共创 · 共赢 · 共享</span>
            <span class="text-right w-[100%]">信用创造价值</span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TitleComp from "./titleComp";
import { useRouter } from "vue-router";
const route = useRouter();
function goAboutUs() {
  route.push({ path: "/aboutus", query: { tab: 'intro' } });
}
</script>

<style scoped lang="scss">
.about-title {
  border-color: var(--el-color-primary);
  @apply inline-block text-[20px] leading-[20px] border-b-4 pb-[8px];
}

.about-copany {
  background: url(../../assets/images/qy.png);
  background-attachment: fixed;
  background-size: 100% 1200px;
  // &::after {
  //   content: "";
  //   width: 100%;
  //   height: 1200px;
  //   background: url(../../assets/images/qy.png);
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   z-index: -1;
  // }
}

.bgimage {
  background-image: url('../../assets/images/companyRight.png');
}

.kh {
  position: relative;

  &::before {
    content: "“";
    font-size: 40px;
    color: var(--el-color-primary);
    position: absolute;
    left: -42px;
    font-family: SimHei;
    top: -14px;
  }

  &::after {
    content: "“";
    font-size: 40px;
    color: var(--el-color-primary);
    font-family: SimHei;
    transform: rotate(-180deg);
    position: absolute;
    right: -42px;
    top: 50px;
  }
}

@media (max-width: 750px) {
  .index-abouts {
    margin-top: 20px;
  }

  .index-abouts .about-copany {
    background-size: auto 100%;
    height: auto;
  }

  .index-abouts .about-copany>div {
    padding: 0;
    margin: 0;
    width: 100%;
    margin-top: 240px;
    padding: 0 3%;
  }

  .index-abouts .about-copany>div>div {
    padding: 30px 3% !important;
  }

  .index-abouts .about-copany>div:last-child>div>div:last-child {
    width: 80%;
    margin: 20px auto 0;
  }
}
</style>
