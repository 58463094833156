<template>
    <div class="flex justify-between">
        <div class="pr-[20px]">
            <li class="doc" v-for="item in list" :key="item.id" @click="push({
                path: '/news/detail', query: { id: item.id, }
            })">{{ item.title }}</li>
        </div>
        <div class="ml-[80px] mt-[60px] mr-[120px]">
            <img class="block w-[305px]" :src="logo" alt="">
            <p class="text-[24px] font-bold mb-[30px]">广晟直通车</p>
            <p class="text-[24px] text-[var(--el-color-primary)] mb-[5px]">所有广晟机会全网首发</p>
            <!-- <p class="text-[16px] opacity-50 text-[var(--el-color-primary)]  mb-[80px]">加入海尔生态圈，您可以拥有</p> -->
            <el-button class="bg-[var(--el-color-primary)]" type="primary" round>
                我要参加<el-icon class="el-icon--right ml-[30px]">
                    <ArrowRight />
                </el-icon>
            </el-button>
        </div>
    </div>
</template>
  
<script lang="ts" setup>
import {
    ArrowRight,
} from '@element-plus/icons-vue'
import { defineProps, ref, watch, withDefaults } from 'vue'
import { getPage } from "@/http/api";
import { IList } from '@/interface/article';
import useNavigation from '@/hooks/useNavigation';

const { push } = useNavigation()
const props = withDefaults(defineProps<{
    logo: string,
    id: string
}>(), {
    logo: '',
    id: ''

})

const list = ref<Array<{ title: string, id: string | number }>>([])
watch(() => props.id, (val) => {
    if (val) {
        getSelPage(val)
    }
}, { immediate: true })
interface IResult {
    data: Array<IList>;
}
function getSelPage(menuId, pageNum = 1) {
    getPage<IResult>({
        menuId,
        pageNum,
        pageSize: 20,
    }).then((res) => {
        list.value = (res.rows as any) || [];
    });
}
</script>

<style scoped lang="scss">
.intro {
    flex: 1;

    .title {
        padding: 10px 0;
        font-size: 26px;
        font-weight: bold;
        border-bottom: 2px solid var(--el-color-primary);
    }
}

.qrcode {
    margin-top: 50px;

    .img {
        width: 120px;
    }
}

.map {
    height: 360px;
    background-color: #8b7f7f;
}

.doc {
    display: flex;
    align-items: center;
    @apply flex-1 text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer text-[#666] text-[16px] leading-[20px] pb-[16px] hover:text-[var(--el-color-primary)];

    &::before {
        content: "";
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: var(--el-color-primary);
        margin-right: 8px;
        flex-shrink: 0;
    }
}

@media (max-width: 750px) {
    .page-joinus>div.content>div.company div.el-tabs__content>div:last-child>div {
        flex-flow: column wrap;
    }

    .page-joinus>div.content>div.company div.el-tabs__content>div:last-child>div img {
        width: 100%;
        height: auto;
    }

    .page-joinus>div.content>div.company div.el-tabs__content>div:last-child>div div {
        margin: 30px 0 0;
    }
}
</style>
  