export default function useGetDevice() {
    //判断访问终端
    const browser = {
        versions: (function () {
            const u = navigator.userAgent
            return {
                isWebKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                isFirebox: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
                isMobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
                isAndroid: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
                isIPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
                isIPad: u.indexOf('iPad') > -1, //是否iPad
                isWebApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
                isWeChat: u.indexOf('MicroMessenger') > -1, //是否微信 （2015-01-22新增）
            }
        })(),
        language: (
            navigator.language || navigator['browserLanguage']
        ).toLowerCase(),
    }
    return {
        ...browser,
    }
}
