import { useStore } from "@/store/index";
import { ref, watch } from "vue";
export default function curCurName(path) {
  const store = useStore();
  const name = ref("");
  const menuId = ref("");
  watch(
    () => store.state.navList,
    () => {
      name.value = store.getters.curMenuItem(path)?.name;
      menuId.value = store.getters.curMenuItem(path)?.id;
    },
    {
      immediate: true,
    }
  );
  return {
    name,
    menuId,
  };
}
