import { useRouter } from "vue-router";
export default function useNavigation() {
    const router = useRouter()
    function push(option) {
        router.push(option)
    }
    return {
        router,
        push
    }
}