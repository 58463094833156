import { createStore, useStore as baseUseStore, Store } from "vuex";
import { InjectionKey } from "vue";
import { INavList, IConfig } from "@/interface/index";

export interface State {
  navList: Array<INavList>;
  configList: Array<IConfig>;
}
export const key: InjectionKey<Store<State>> = Symbol();
export default createStore<State>({
  state: {
    navList: [],
    configList: []
  },
  getters: {
    // 当前菜单项
    curMenuItem(state) {
      return (menuPath) => {
        return state.navList.find((menu) => menu.path === menuPath);
      };
    },
    // 当前菜单项
    getQRCodes(state) {
      return () => {
        return state.configList.find((item) => item.wechatQrcode && item.mobileQrcode);
      };
    },
  },
  mutations: {
    setFacultyMenuKind(state, payload) {
      state.navList = payload;
    },
    setConfigList(state, payload) {
      state.configList = payload;
    },
  },
  actions: {},
  modules: {},
});

export function useStore() {
  return baseUseStore(key);
}
