<template>
    <!-- 地图展示 -->
    <div id="container" class="mapDisplay"></div>
</template>
  
<script lang="ts" setup>
import { ref } from 'vue';
import AMapLoader from '@amap/amap-jsapi-loader';

window._AMapSecurityConfig = {
    securityJsCode: '',
}
AMapLoader.load({
    "key": "e900c5f34357ace3fbd67158560c3ee2",              // 申请好的Web端开发者Key，首次调用 load 时必填
    "version": "1.4.15",   // 指定要加载的 JS API 的版本，缺省时默认为 1.4.15
    "plugins": [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
}).then((AMap) => {
    // const satellite = new AMap.TileLayer.Satellite();// 卫星图
    const roadNet = new AMap.TileLayer.RoadNet();// 路网图
    const map = new AMap.Map('container', {
        zoom: 12, // 初始化地图级别
        mapStyle: 'amap://styles/whitesmoke',  //设置地图的显示样式
        center: [113.32, 23.13],
        layers: [
            roadNet
            // satellite
        ],
        showLabel: true
    });
    // const trafficLayer = new AMap.TileLayer.Traffic({
    //     zIndex: 11,
    //     zooms: [7, 22],
    // });
    // trafficLayer.setMap(map);
    // 添加控件
    // 地图放大缩小插件
    // let toolBar = new AMap.ToolBar({
    //     position: {
    //         top: '120px',
    //         right: '51px'
    //     }
    // })
    // AMap.plugin(
    //     [
    //         'AMap.ElasticMarker',
    //         'AMap.Scale',
    //         'AMap.ToolBar',
    //         'AMap.HawkEye',
    //         'AMap.MapType',
    //         'AMap.Geolocation',
    //     ],
    //     () => {
    //         map.addControl(new AMap.ElasticMarker()) // map.addControl为地图添加对应的控件
    //         map.addControl(new AMap.Scale())
    //         map.addControl(new AMap.ToolBar())
    //         map.addControl(new AMap.HawkEye())
    //         map.addControl(new AMap.MapType())
    //         map.addControl(new AMap.Geolocation())
    // }
    // )
    
    const marker = new AMap.Marker({
        icon: "",
        position: [113.32, 23.13]
    });
    // map.addControl(toolBar);   // 添加右上角的放大缩小
    map.add(marker);
    map.setFitView();
}).catch(e => {
    console.log(e);
})



</script>

<style scoped lang="scss">
.mapDisplay {
    width: 100%;
    height: 360px;
}
</style>
  