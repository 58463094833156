import { getLeaf } from "@/http/api/index";
import useGetContext from "@/hooks/useGetContext";
import { onUnmounted } from "vue";
export default function useGetNewsNavList<T>(menuPath, callBack, option:Array<T> = []) {
  const { proxy, getMenuId } = useGetContext();

  function getNewsInfo() {
    const { newMenu } = getMenuId(menuPath) || {};
    if (!newMenu.id) return;
    if (option.length) {
      const promiseList: any = [];
      for (let index = 0; index < option.length; index++) {
        const params = option[index] as any;
        promiseList.push(getLeaf({ menuId: newMenu.id, ...params }));
      }
      Promise.all(promiseList).then((res) => {
        callBack &&
          callBack({
            data: res,
            menuId: newMenu.id,
          });
      });
    } else {
      return getLeaf({ menuId: newMenu.id}).then((res) => {
        callBack &&
          callBack({
            data: res.data,
            menuId: newMenu.id,
          });
      });
    }
  }
  proxy.$eventBus.on("navMenuInit", getNewsInfo);
  onUnmounted(() => {
    proxy.$eventBus.off("navMenuInit", getNewsInfo);
  });

  return {
    getNewsInfo,
  };
}
