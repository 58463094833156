<template>
    <div class="financialservice">
        <div class="adv">
            <p class="title">金融服务</p>
            <i class="sub">FINANCIAL SERVICE</i>
        </div>
        <div class="content">
            <div class="path">
                <img class="location" src="@/assets/images/location.png" alt="" srcset="">
                <span class="label">当前位置:</span>
                <span>首页 > 金融服务</span>
            </div>
            <div class="flex gap-[40px] bg-white p-[60px]">
                <div class="w-[220px] bg-[#F5F5F5]">
                    <p
                        class="w-full h-[70px] text-[22px] inline-flex justify-center items-center font-bold text-[#fff] bg-[#333]">
                        金融服务</p>
                    <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
                        <el-sub-menu :index="item.id" v-for="item in sidebarList" :key="item.id">
                            <template #title>
                                <i class="iconfont icon-daezijin mr-[5px]"></i>
                                <span>{{item.title}}</span>
                            </template>
                            <el-menu-item v-for="v in item.leftList" :key="v.id" :index="v.id" @click="handleClick(v)">{{ v.title }}</el-menu-item>
                        </el-sub-menu>
                    </el-menu>
                </div>
                <div class="flex-1" v-if="modelName === 'list'">
                    <div class="item py-[30px] px-[40px] border-[#DEDEDE] border-b" v-for="item in articleList" :key="item.id">
                        <p class="title text-[20px] mb-[10px]">{{ item.title }}</p>
                        <p class="text-[16px] mb-[20px]">{{ item.digest }}</p>
                        <el-button class="btn h-[40px]" @click="handleForwardDetail(item)">
                            查看详情<el-icon class="el-icon--right">
                                <ArrowRight />
                            </el-icon>
                        </el-button>
                    </div>
                    <div class="flex justify-center mt-[20px]">
                        <el-pagination background layout="prev, pager, next" :total="articleListTotal" />
                    </div>
                </div>
                <div class="relative flex-1 py-[30px] px-[40px]" v-else>
                    <el-icon class="absolute left-[30px] top-[5px] text-[28px] hover:cursor-pointer" @click="handleBack">
                        <Back />
                    </el-icon>
                    <p class="text-[26px] text-[#333] mb-[10px] text-center">{{ articleDetial.title }}</p>
                    <div class="relative text-center mb-[20px]">
                        <i class="iconfont icon-xiangxiajiantou1"></i>
                        <img class="absolute bottom-[10px] left-[19.5%] w-[450px] inline-block"
                            src="@/assets/images/line.png" alt="" srcset="">
                    </div>
                    <!-- <img class="block w-full h-[300px] mb-[20px]" :src="articleDetial.banner" alt=""> -->
                    <p class="text-[16px] text-[#333] leading-[1.8] indent-[2em]" v-html="articleDetial.content"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from "vue";
import {
    Back,
    ArrowRight,
} from '@element-plus/icons-vue';
import { getLeaf, getPage } from "@/http/api/index";
import useGetContext from "@/hooks/useGetContext";
import { IList, IItem } from '@/interface/article.d';

const { proxy, getMenuId } = useGetContext();

const sidebarList = ref<IList[]>([]);

const modelName = ref('list');

const articleList = ref<Array<IItem>>([]);

const articleListTotal = ref<Array<IItem>>([]);

const activeIndex = ref();

const articleDetial = ref<Partial<IItem>>({})

const handleOpen = (key: string, keyPath: string[]) => {
    console.log(key, keyPath)
}
const handleClose = (key: string, keyPath: string[]) => {
    console.log(key, keyPath)
}

const handleClick = (item: IItem) => {
    getSelPage(item.id, 1);
    modelName.value = 'list';
}

const handleForwardDetail = (item) => {
    modelName.value = 'detail';
    articleDetial.value = item;

}
const handleBack = () => {
    modelName.value = 'list';
}

function getNewsInfo() {
    const { newMenu } = getMenuId("/financial") || {};
    getLeaf({ menuId: newMenu.id }).then((res: any) => {
        sidebarList.value = res.data.leftList;
        const id = res.data.leftList[0]?.leftList?.[0].id;
        getSelPage(id, 1);
        activeIndex.value = id;
    });
}
onMounted(() => {
    const { newMenu } = getMenuId("/financial") || {};
    if(newMenu?.id) {
        getNewsInfo();
    } else {
        proxy.$eventBus.on("navMenuInit", getNewsInfo);
    }
});
onUnmounted(() => {
    proxy.$eventBus.off("navMenuInit", getNewsInfo);
});
const getSelPage = (menuId, pageNum) => {
    getPage({
        menuId,
        pageNum,
        pageSize: 20,
    }).then((res: any) => {
        if(res.code === 200) {
            articleList.value = res.rows;
            articleListTotal.value = res.total;
        }
    });
}
</script>

<style scoped lang="scss">
.financialservice {
    min-height: 100%;
    padding-bottom: 50px;
    background-color: #f7f4f4;
}

.adv {
    padding: 120px 0;
    height: 320px;
    text-align: center;
    background: url('@/assets/images/aboutus.png') center no-repeat;
    background-size: cover;

    .title {
        display: block;
        font-size: 50px;
        font-weight: bold;
        color: #fff;
    }

    .sub {
        font-size: 20px;
        color: #fff;
    }
}

.content {
    width: 1200px;
    min-height: 300px;
    margin: 0 auto;

    .company {
        padding: 60px;
        background-color: #fff;
    }

    .path {
        padding: 18px 0;
        color: #666;
        font-size: 14px;

        .location {
            display: inline-block;
            width: 21px;
            height: 26px;
            margin-right: 5px;
            vertical-align: top;
        }

        .label {
            margin-right: 10px;
            color: #333;
            font-size: 16px;
        }
    }

    .item:hover {
        cursor: pointer;
        border-radius: 4px;
        box-shadow: 0 0 8px #b8b8b8;

        .title {
            color: var(--el-color-primary);
        }

        .btn {
            background-color: var(--el-color-primary);
            color: #fff;
        }
    }
}
@media (max-width: 750px){
    .financialservice > div.content{
        width: 100%;
    }
    .financialservice > div.content > div.path{
        padding: 20px 3%;
    }
    .financialservice > div.content > div:last-child{
        padding: 40px 3%;
        flex-flow: column wrap;
    }
    .financialservice > div.content > div:last-child > div:first-child{
        width: 100%;
    }
    .financialservice > div.content > div:last-child > div:first-child p{
        display: none;
    }
    .financialservice > div.content > div:last-child > div:first-child > ul{
        display: flex;
        flex-flow: row nowrap;
        justify-content:center;
        align-items:center;
    }
    .financialservice > div.content > div:last-child > div:first-child > ul > li{
        position: relative;
        width: 31%;
        margin: 0 3px;
    }
}
</style>