<template>
  <div class="bg-white pt-[60px] mutilist-wrap">
    <div class="flex items-center justify-center mutilist-nav">
      <div
        v-for="(item, index) in NavList"
        :key="item.id"
        class="list-item"
        :class="activeIndex === index ? 'active' : ''"
        @click="changeActive(index)"
      >
        {{ item.title }}
      </div>
    </div>
    <div
      class="content w-[100%] box-border pt-[60px] mutilist-swiper"
      :style="`--active-index: ${activeIndex}`"
      v-show="!isEmpty"
    >
      <div class="new-list flex relative z-10">
        <CommonSwiper v-bind="$attrs" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, withDefaults, ref, defineEmits } from "vue";
import CommonSwiper from "./CommonSwiper.vue";
export interface IListItem {
  id: string;
  title: string;
  banner?: string;
  content?:string;
  digest?:string;
  children?: Array<IListItem>;
}
export interface IProps {
  NavList: Array<IListItem>;

  isEmpty?:boolean;
}
withDefaults(defineProps<IProps>(), {
  NavList: () => [] as Array<IListItem>,
});
const activeIndex = ref(0);
const emit = defineEmits(['hanldeChange'])
function changeActive(index) {
  activeIndex.value = index;
  emit('hanldeChange', index)
}
</script>

<style scoped lang="scss">
.list-item {
  &:hover,
  &.active {
    background-color: var(--el-color-primary);
    color: #fff;
  }
  &.active {
    $row-width: 6px;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-top: $row-width solid #fff;
      border-left: $row-width solid #fff;
      border-bottom: $row-width solid transparent;
      border-right: $row-width solid transparent;
      transform: rotate(45deg) translateX(-50%);
      left: 50%;
      bottom: -11px;
      @apply absolute;
    }
  }
  @apply cursor-pointer pl-[16px] pr-[25px] py-[10px] flex items-center mr-[24px] bg-[#ccc] rounded-md;
}
.content {
  @apply relative;

  .new-list {
    $row-width: 5px;
  }
}
@mixin boxShaow() {
  box-shadow: 0 0 10px #999;
}
</style>
