<template>
  <div class="px-[60px] pb-[60px] box-sizing w-[100%] common-swiper newsconn-info">
    <swiper
      :modules="modules"
      :slides-per-view="1"
      :autoplay="{ delay: 5000 }"
      :loop="true"
      style="height: 340px"
      :navigation="navigation"
      :pagination="{ clickable: true }"
      :scrollbar="{ draggable: false }"
      class="swiperBox"
      effect="fade"
      :fadeEffect="{ crossFade: true }"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        v-for="item in swiperList || []"
        :key="item.id"
        class="cursor-pointer"
      >
        <div class="flex relative bg-white w-[1080px]">
          <img
            :src="item.banner"
            class="w-[540px] h-[280px] mr-[36px] object-cover"
          />
          <div class="w-[504px] ">
            <h3 class="text-[18px] text-[#333] leading-[20px] mb-[20px] hover:text-[var(--el-color-primary)]" @click="toDetail(item)">
              {{ item.title }}
            </h3>
            <span
              class="text-[14px] leading-[14px] text-[#999] inline-block border-b-[2px] border-[#666] pb-[5px]"
              >{{ item.createTime }}</span
            >
            <p class="desc indent-[2em]">
              {{ item.digest }}
            </p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script setup lang="ts">
import { ref, toRefs, useAttrs, watch,defineEmits, nextTick } from "vue";
import {
  Autoplay,
  Pagination,
  Navigation,
  Scrollbar,
  EffectFade,
} from "swiper";
import { Swiper } from "swiper/vue";
const navigation = ref({
  nextEl: ".swiper-button-next",
  prevEl: ".swiper-button-prev",
});
import "swiper/css/pagination"; // 轮播图底面的小圆点
import "swiper/css/navigation"; // 轮播图两边的左右箭头
import "swiper/css/effect-fade"; // 淡入淡出
// 在modules加入要使用的模块
const modules = [Autoplay, Pagination, Navigation, Scrollbar, EffectFade];
const emit = defineEmits(['toDetail'])
const { swiperList } = toRefs(useAttrs()) as any;
const swiperInstance = ref<
  (InstanceType<typeof Swiper> & { slideTo: (index: number) => void }) | null
>(null);
function onSwiper(swiper) {
  swiperInstance.value = swiper;
}

function onSlideChange(swiper) {
  console.log(swiper);
}

function toDetail(item) {
  emit('toDetail',item)
}
</script>

<style scoped lang="scss">
.desc {
  @apply w-[100%] text-[14px] text-[#666] mt-[20px] leading-[22px];
  width: 504px; /* 定义容器宽度 */
  word-break: break-all; /* 当遇到不能被分开的字符（比如汉语）时也进行断行 */
  overflow-wrap: break-word; /* 支持更多的换行机制 */
  display: -webkit-box; /* 将文本显示为块元素 */
  -webkit-line-clamp: 6; /* 设置最大显示行数为2 */
  -webkit-box-orient: vertical; /* 设置块元素内部文本垂直排列 */
  overflow: hidden; /* 当文本溢出块元素时进行隐藏 */
  text-overflow: ellipsis; /* 溢出部分显示省略号 */
}
:deep(.swiper-pagination-bullet-active) {
  background-color: var(--el-color-primary);
}
</style>
