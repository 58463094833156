import { getCurrentInstance } from "vue";
import { useStore } from "@/store/index";
export default () => {
  const { proxy } = getCurrentInstance() as any;
  const store = useStore();
  return {
    store,
    proxy,
    getMenuId: function (menuPath) {
      const newMenu = store.getters.curMenuItem(menuPath) || {};
      return {
        newMenu,
      };
    },
  };
};
